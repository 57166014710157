import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { login, testLogin } from "../../actions";
import propTypes from "prop-types";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import { TextField, Button } from "@material-ui/core";
import ForgotPassword from "../../layouts/LoginLayout/ForgotPassword";
import "./index.scss";

class doctorLogInForm extends Component {
  state = {
    isForgotPasswordDialog: false,
  };
  DoctorLogInSchema = () => {
    const { t: _t } = this.props;
    return Yup.object().shape({
      login: Yup.string().required(_t("Mandatory input")),
      password: Yup.string().required(_t("Mandatory input")),
    });
  };

  // login = ({ login, password }) => {
  //   this.props.login(login, password);
  // };
  login = form => {
    console.warn("Данные формы-------------", form);
  };

  toggleForgotPasswordDialog = () => {
    this.setState({ isForgotPasswordDialog: !this.state.isForgotPasswordDialog });
  };

  render() {
    const { t: _t, networkErrors } = this.props;
    return (
      <div className="doctorLogInForm">
        <Formik
          initialValues={{
            login: "",
            password: "",
          }}
          validationSchema={this.DoctorLogInSchema()}
          onSubmit={this.login}
          render={({ errors }) => (
            <Form>
              <div className="doctorLogInForm__wrap">
                <Field
                  name="login"
                  render={({ field }) => (
                    <TextField
                      type="string"
                      label={_t("Login")}
                      className="doctorLogInForm__input"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      error={!!errors.login}
                      helperText={errors.login}
                      {...field}
                    />
                  )}
                />
                <Field
                  name="password"
                  render={({ field }) => (
                    <TextField
                      type="password"
                      label={_t("Password")}
                      className="doctorLogInForm__input"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      error={!!errors.password}
                      helperText={errors.password}
                      {...field}
                    />
                  )}
                />
                <div style={{ maxWidth: 300 }}>
                  {networkErrors && networkErrors.length > 0 && <div>{_t("Wrong login or password!")}</div>}
                </div>
                {/* <Button type="submit" variant="contained" color="primary" className="btn doctorLogInForm-btn">
                  {_t("Log in")}
                </Button> */}
                <Button
                  variant="contained"
                  color="primary"
                  className="btn doctorLogInForm-btn"
                  onClick={() => this.props.testLogin("doctor")}
                >
                  {_t("Log in")}
                </Button>
                <p className="doctorLogInForm-btn-forgot" onClick={this.toggleForgotPasswordDialog}>
                  {_t("Forgot your password?")}
                </p>
              </div>
            </Form>
          )}
        />
        <ForgotPassword isShow={this.state.isForgotPasswordDialog} onClose={this.toggleForgotPasswordDialog} />
      </div>
    );
  }
}

doctorLogInForm.propTypes = {
  t: propTypes.func.isRequired,
};

const mapStateToProps = ({ login, profile }) => ({
  networkErrors: login.errors,
});

const mapDispatchToProps = {
  login,
  testLogin,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(doctorLogInForm));
