import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
// import { API } from "../../../modules/Api";
// import Logger from "../../../modules/Logger";
import { Dialog, Toolbar, AppBar, IconButton, Paper, TextField } from "@material-ui/core";
import "./index.scss";

class ChangePassword extends PureComponent {
  ChangePasswordSchema = () => {
    const { t: _t } = this.props;
    return Yup.object().shape({
      oldPassword: Yup.string().required(_t("Required")),
      newPassword: Yup.string()
        .required(_t("Required"))
        .min(8, "Password is too short - should be 8 chars minimum."),
      newPassword2: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required(_t("Required")),
    });
  };

  changePassword = form => {
    console.warn("Данные формы \"ChangePassword\": ", form);
  };

  // resetPassword = ({ email }) => {
  //   API.post("user/reset_password", { email }).then(
  //     response => {
  //       Logger.info(response);
  //       this.props.onClose();
  //     },
  //     error => {
  //       Logger.info(error);
  //     },
  //   );
  // };
  render() {
    const { t: _t, isShow, onClose } = this.props;
    return (
      <Dialog
        fullScreen
        open={false}
        onClose={onClose}
        style={{ zIndex: 12000 }}
        aria-labelledby="dialog-ChangePassword"
        className="dialog-ChangePassword simpleDialog"
      >
        <AppBar className="dialog-ChangePassword-appBar">
          <Toolbar className="dialog-ChangePassword-toolBar">
            <IconButton color="inherit" onClick={onClose} aria-label="close">
              {_t("Close")}
            </IconButton>
          </Toolbar>
        </AppBar>
        <p className="dialog-ChangePassword-title">{_t("Сhange Password")}</p>
        <Paper className="dialog-ChangePassword-paper">
          <Formik
            initialValues={{
              oldPassword: "",
              newPassword: "",
              newPassword2: "",
            }}
            validationSchema={this.ChangePasswordSchema()}
            onSubmit={this.changePassword}
            render={({ errors }) => (
              <Form id="ChangePassword-form">
                <Field
                  name="oldPassword"
                  render={({ field }) => (
                    <TextField
                      label={_t("Old Password")}
                      type="string"
                      margin="dense"
                      variant="outlined"
                      className="ChangePassword-form-input"
                      error={!!errors.oldPassword}
                      helperText={errors.oldPassword}
                      {...field}
                    />
                  )}
                />
                <Field
                  name="newPassword"
                  render={({ field }) => (
                    <TextField
                      label={_t("New Password")}
                      type="string"
                      margin="dense"
                      variant="outlined"
                      className="ChangePassword-form-input"
                      error={!!errors.newPassword}
                      helperText={errors.newPassword}
                      {...field}
                    />
                  )}
                />
                <Field
                  name="newPassword2"
                  render={({ field }) => (
                    <TextField
                      label={_t("New Password again")}
                      type="string"
                      margin="dense"
                      variant="outlined"
                      className="ChangePassword-form-input"
                      error={!!errors.newPassword2}
                      helperText={errors.newPassword2}
                      {...field}
                    />
                  )}
                />
                <button type="submit" className="invite-btn">
                  {_t("Change")}
                </button>
              </Form>
            )}
          />
        </Paper>
      </Dialog>
    );
  }
}

ChangePassword.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(ChangePassword);
