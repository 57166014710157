import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { testLogin } from "../../../actions";
import { TextField, Checkbox, FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import { DeleteForever } from "@material-ui/icons";
import "./index.scss";
import step_icon from "../../../assets/imgs/step_icon.png";
import save_icon from "../../../assets/imgs/save_icon.png";
import step1_logo from "../../../assets/imgs/step1_logo.png";
import step3_1 from "../../../assets/imgs/step3_1.png";
import step3_2 from "../../../assets/imgs/step3_2.png";
import step3_3 from "../../../assets/imgs/step3_3.png";
import step3_4 from "../../../assets/imgs/step3_4.png";
import step3_5 from "../../../assets/imgs/step3_5.png";
import step3_6 from "../../../assets/imgs/step3_6.png";
import step4_example from "../../../assets/imgs/step4_example.png";
import step4_1 from "../../../assets/imgs/step4_1.png";
import step4_2 from "../../../assets/imgs/step4_2.png";
import step4_3 from "../../../assets/imgs/step4_3.png";
import step4_4 from "../../../assets/imgs/step4_4.png";
import step5_ex from "../../../assets/imgs/step5_ex.png";
import step5_1 from "../../../assets/imgs/step5_1.png";
import step5_2 from "../../../assets/imgs/step5_2.png";
import step5_3 from "../../../assets/imgs/step5_3.png";
import step5_11 from "../../../assets/imgs/step5_11.png";
import step5_12 from "../../../assets/imgs/step5_12.png";
import step5_13 from "../../../assets/imgs/step5_13.png";
import step5_14 from "../../../assets/imgs/step5_14.png";

const step3BgList = [
  {
    id: 1,
    pic: step3_1,
    title: "BLUE light",
  },
  {
    id: 2,
    pic: step3_2,
    title: "GRAY light",
  },
  {
    id: 3,
    pic: step3_3,
    title: "GREEN light",
  },
  {
    id: 4,
    pic: step3_4,
    title: "RED night",
  },
  {
    id: 5,
    pic: step3_5,
    title: "PURPLE night",
  },
  {
    id: 6,
    pic: step3_6,
    title: "TURQUOISE night",
  },
];
const step4BgList = [
  {
    id: 1,
    pic: step4_1,
  },
  {
    id: 2,
    pic: step4_2,
  },
  {
    id: 3,
    pic: step4_3,
  },
  {
    id: 4,
    pic: step4_4,
  },
];
const step5BgList = [
  {
    id: 1,
    pic: step5_1,
  },
  {
    id: 2,
    pic: step5_2,
  },
  {
    id: 3,
    pic: step5_3,
  },
];
const step5GradientList = [
  {
    id: 1,
    pic: step5_11,
  },
  {
    id: 2,
    pic: step5_12,
  },
  {
    id: 3,
    pic: step5_13,
  },
  {
    id: 4,
    pic: step5_14,
  },
];

class RegisterSteps extends PureComponent {
  someId = 100;
  state = {
    currentStep: 1,
    imagePreviewUrl: step1_logo,
    logo: "",
    companyName: "",
    country: "",
    city: "",
    tel: "",
    address: "",
    email: "",
    lang: "en",
    step3_bg: "",
    step4_bg: "",
    step5_bg: "",
    step5_gradient: "",
    step5_subdomain: "",
    priceRows: [
      { id: 1, isCheck: true, name: "Panoramic X-ray (OPG)", price: "30" },
      { id: 2, isCheck: false, name: "Cephalometric X-ray (TRG)", price: "0" },
      { id: 3, isCheck: false, name: "Segment (1-2 teeth) 4x5 cm extent of test", price: "0" },
      { id: 4, isCheck: false, name: "Segment (1-2 teeth) 4x8 cm extent of test", price: "0" },
      { id: 5, isCheck: false, name: "Jaw 10x5 cm extent of test ", price: "0" },
      {
        id: 6,
        isCheck: false,
        name: "Upper and lower jaw + maxillary sinuses 10x10 cm extent of test + Proface",
        price: "0",
      },
      { id: 7, isCheck: false, name: "Temporomandibular joint left and right", price: "0" },
      { id: 8, isCheck: false, name: "Upper jaw + extra sinuses", price: "0" },
      { id: 9, isCheck: false, name: "Upper and lower jaw + extra sinuses", price: "0" },
    ],
    newItem: "",
    currency: "$",
  };

  showState = () => {
    const stepsData = {
      logo: this.state.logo,
      companyName: this.state.companyName,
      country: this.state.country,
      city: this.state.city,
      tel: this.state.tel,
      address: this.state.address,
      email: this.state.email,
      lang: this.state.lang,
      step3_bg: this.state.step3_bg,
      step4_bg: this.state.step4_bg,
      step5_gradient: this.state.step5_gradient,
      step5_gradient: this.state.step5_gradient,
      step5_subdomain: this.state.step5_subdomain,
    };
    console.log(stepsData);
  };

  handleCurrency = e => {
    this.setState({ currency: e.target.value }, () => {
      console.log(this.state.currency);
    });
  };

  /* 
  // если надо FormData
  send = () => {
    let a = {
      name: "bla",
      file: this.state.logo,
    };

    let formData = new FormData();
    let keys = Object.keys(a);
    for (let i = 0; i < keys.length; i++) {
      formData.append(keys[i], a[keys[i]]);
    }
  };
*/

  currentStep = step => {
    this.setState({
      currentStep: step,
    });
  };

  back = () => {
    this.setState({
      currentStep: this.state.currentStep - 1,
    });
    if (this.state.currentStep <= 1) {
      this.setState({
        currentStep: 1,
      });
    }
  };

  next = () => {
    this.setState({
      currentStep: this.state.currentStep + 1,
    });
    if (this.state.currentStep >= 5) {
      this.setState({
        currentStep: 5,
      });
    }
  };

  addItem = (e, item) => {
    e.preventDefault();
    this.setState(state => {
      const addedItem = { id: this.someId++, isCheck: true, name: item, price: "0" };
      return { priceRows: [...state.priceRows, addedItem], newItem: "" };
    });
  };
  deleteItem = id => {
    this.setState({ priceRows: this.state.priceRows.filter(el => el.id !== id) });
  };

  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChange = e => {
    this.setState({
      lang: e.target.value,
    });
  };
  step3 = id => {
    this.setState({
      step3_bg: id,
    });
  };
  step4 = id => {
    this.setState({
      step4_bg: id,
    });
  };
  step5Bg = id => {
    this.setState({
      step5_bg: id,
    });
  };
  step5Gradient = id => {
    this.setState({
      step5_gradient: id,
    });
  };

  uploadLogo(e) {
    e.preventDefault();
    let reader = new FileReader();
    let logo = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        logo,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(logo);
  }

  changePriceRow = (id, name, value) => {
    this.setState({ priceRows: this.state.priceRows.map(el => (el.id === id ? { ...el, [name]: value } : el)) });
  };

  render() {
    const { t: _t } = this.props;
    const {
      currentStep,
      companyName,
      country,
      city,
      tel,
      address,
      email,
      lang,
      step5_subdomain,
      logo,
      imagePreviewUrl,
      priceRows,
      newItem,
      currency,
    } = this.state;
    const invisible = {
      opacity: "0",
      pointerEvents: "none",
    };
    const visible = {
      opacity: "1",
      pointerEvents: "auto",
    };

    return (
      <div className="register">
        <div className="register__steps">
          <div
            className="register__btn register__btn_back"
            style={currentStep > 1 ? visible : invisible}
            onClick={this.back}
          >
            <div className="register__circle">
              <img src={step_icon} alt="" />
            </div>
            <span>Back</span>
          </div>
          <div className="register__stepNum active" onClick={() => this.currentStep(1)}>
            1
          </div>
          <div className={`register__stepNum ${currentStep >= 2 ? "active" : ""}`} onClick={() => this.currentStep(2)}>
            2
          </div>
          <div className={`register__stepNum ${currentStep >= 3 ? "active" : ""}`} onClick={() => this.currentStep(3)}>
            3
          </div>
          <div className={`register__stepNum ${currentStep >= 4 ? "active" : ""}`} onClick={() => this.currentStep(4)}>
            4
          </div>
          <div className={`register__stepNum ${currentStep >= 5 ? "active" : ""}`} onClick={() => this.currentStep(5)}>
            5
          </div>
          {currentStep === 5 && (
            <div className="register__btn register__btn_next" onClick={() => this.props.testLogin("owner")}>
              <span>Save</span>
              <div className="register__circle">
                <img src={save_icon} alt="" />
              </div>
            </div>
          )}
          {currentStep !== 5 && (
            <div className="register__btn register__btn_next" onClick={this.next}>
              <span>Next</span>
              <div className="register__circle">
                <img src={step_icon} alt="" />
              </div>
            </div>
          )}
        </div>
        {currentStep === 1 && (
          <div className="register__step register__step_1">
            <h2>{_t("General Information")}</h2>
            <p className="register__text">{_t("Add Logo")}</p>
            <div className="register__logoBox">
              <div className="register__logo">
                <img src={imagePreviewUrl} alt="" className={`${logo && "loaded"}`} />
              </div>
              <div className="register__btn">
                <label htmlFor="logo">{_t("UPLOAD LOGO")}</label>
                <input type="file" id="logo" onChange={e => this.uploadLogo(e)} />
              </div>
            </div>
            <p className="register__text">{_t("Add information about the company")}</p>
            <div className="register__inputBox">
              <TextField
                ref={this.textInput}
                label={"Company's name"}
                variant="outlined"
                name="companyName"
                value={companyName}
                onChange={this.handleInput}
              />
              <TextField
                label={"Country"}
                variant="outlined"
                name="country"
                value={country}
                onChange={this.handleInput}
              />
              <TextField label={"City"} variant="outlined" name="city" value={city} onChange={this.handleInput} />
              <TextField
                type="tel"
                label={"Tel/Mob"}
                variant="outlined"
                name="tel"
                value={tel}
                onChange={this.handleInput}
              />
              <TextField
                label={"Address"}
                variant="outlined"
                name="address"
                value={address}
                onChange={this.handleInput}
              />
              <TextField label={"Email"} variant="outlined" name="email" value={email} onChange={this.handleInput} />
              <div className="register__select">
                <p>Language</p>
                <select name="lang" onChange={this.handleInput} value={lang}>
                  <option value="en">EN</option>
                  <option value="ru">RU</option>
                  <option value="ua">UA</option>
                </select>
              </div>
            </div>
          </div>
        )}
        {currentStep === 2 && (
          <div className="register__step register__step_2">
            <h2>{_t("Services and Price List")}</h2>
            <p className="register__text">{_t("Create your price list from the list of services")}</p>
            <div className="currencyWrap">
              <p>Currency</p>
              <select value={currency} onChange={this.handleCurrency}>
                <option value="$">USD</option>
                <option value="€">EUR</option>
                <option value="₴">UAH</option>
              </select>
            </div>
            <div className="prices">
              {priceRows.map(({ id, isCheck, name, price }) => (
                <div className="price" key={id}>
                  <Checkbox
                    color="primary"
                    onChange={() => this.changePriceRow(id, "isCheck", !isCheck)}
                    checked={isCheck}
                  />
                  <p className="name">
                    {name}
                    <DeleteForever className="delete" onClick={() => this.deleteItem(id)} />
                  </p>

                  <div className="money">
                    <input
                      type="text"
                      className="number"
                      value={price}
                      onChange={e => this.changePriceRow(id, "price", e.target.value)}
                    />
                    <div className="currency">{currency}</div>
                  </div>
                </div>
              ))}
              <form className="add" onSubmit={e => this.addItem(e, newItem)}>
                <input type="text" name="newItem" value={newItem} onChange={this.handleInput} />
                <input type="submit" onClick={e => this.addItem(e, newItem)} value="+Add item manually" />
              </form>
            </div>
          </div>
        )}
        {currentStep === 3 && (
          <div className="register__step register__step_3">
            <h2>{_t("Select a design theme")}</h2>
            <p className="register__text">
              {_t(
                "The selected concept will be used in your company profile and the profile of the doctors who are registered in your company.",
              )}
            </p>
            <div className="register__wrap">
              {step3BgList.map(el => (
                <div className="register__item" key={el.id} onClick={() => this.step3(el.id)}>
                  <img src={el.pic} alt="" />
                  <div className={`register__radio ${el.id === this.state.step3_bg && "checked"}`}>
                    <span>{el.title}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {currentStep === 4 && (
          <div className="register__step register__step_4">
            <h2>{_t("Select a theme for the left sidebar")}</h2>
            <p className="register__text">
              {_t(
                "Select a background of the left sidebar to customize the profile. 6 design concepts and 4 background options of the left sidebar will create 24 profile options. This concept will help make your the company unique.",
              )}
            </p>
            <div className="register__wrap">
              <img src={step4_example} alt="" className="register__example" />
              {step4BgList.map(el => (
                <div className="register__item" key={el.id} onClick={() => this.step4(el.id)}>
                  <img src={el.pic} alt="" />
                  <div className={`register__radio ${el.id === this.state.step4_bg && "checked"}`}></div>
                </div>
              ))}
            </div>
          </div>
        )}
        {currentStep === 5 && (
          <div className="register__step register__step_5">
            <h2>{_t("Login page settings")}</h2>
            <p className="register__text">{_t("Enter your company's name for the subdomain.")}</p>
            <div className="register__subdomain">
              <input
                type="text"
                name="step5_subdomain"
                placeholder="Subdomain Name"
                value={step5_subdomain}
                onChange={this.handleInput}
              />
              <div className="register__address">.xrayc.com</div>
            </div>
            <p className="register__text_small">
              {_t("This must be done now in order to generate a page for login to your system of doctors.")}
            </p>
            <p className="register__text_small">
              {_t("The word should not contain spaces, capital letters or special characters.")}
            </p>
            <p className="register__text">{_t("Select a design theme for the login page to your system.")}</p>
            <div className="register__wrap">
              <div className="register__example">
                <p>
                  {_t(
                    "You need to customize your login page for doctors so that they visually distinguish your system. Select a background image and a fill color. After filling out the sections, your login page will be unique.",
                  )}
                </p>
                <img src={step5_ex} alt="" />
              </div>
              <div className="register__inner">
                <div className="register__bg">
                  {step5BgList.map(el => (
                    <div className="register__item" key={el.id} onClick={() => this.step5Bg(el.id)}>
                      <img src={el.pic} alt="" />
                      <div className={`register__radio ${el.id === this.state.step5_bg && "checked"}`}></div>
                    </div>
                  ))}
                </div>
                <div className="register__gradient">
                  {step5GradientList.map(el => (
                    <div className="register__item" key={el} onClick={() => this.step5Gradient(el.id)}>
                      <img src={el.pic} alt="" />
                      <div className={`register__radio ${el.id === this.state.step5_gradient && "checked"}`}></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

RegisterSteps.propTypes = {
  t: propTypes.func.isRequired,
};
const mapStateToProps = () => ({});
const mapDispatchToProps = {
  testLogin,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(RegisterSteps));
