import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Button, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import f from "../../../assets/imgs/f.png";
import i from "../../../assets/imgs/i.png";
import y from "../../../assets/imgs/y.png";

import "./index.scss";

class Header extends PureComponent {
  state = {
    scroll: 0,
    inMenuOpen: false,
    lang: 1,
    isMobile: false,
  };

  componentDidMount() {
    if (window.innerWidth < 992) {
      this.setState({ isMobile: true });
    }
    this.setState({
      inMenuOpen: false,
    });
    window.addEventListener("scroll", this.scrollNav, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNav);
  }

  scrollNav = () => {
    this.setState({
      scroll: window.scrollY,
    });
    if (window.innerWidth < 992) {
      this.setState({ isMobile: true });
    }
  };

  toggleNav = () => {
    this.setState({
      inMenuOpen: !this.state.inMenuOpen,
    });
  };
  langHandler = e => {
    this.setState({
      lang: e.target.value,
    });
  };
  render() {
    const { t: _t } = this.props;
    const { inMenuOpen, scroll, lang, isMobile } = this.state;
    return (
      <div className={scroll > 200 ? "navWrap sticky" : "navWrap"}>
        <div className="container">
          <nav className="nav">
            <div className="socials">
              <a href="https://www.facebook.com/" className="social">
                <img src={f} alt="" />
              </a>
              <a href="https://www.instagram.com/" className="social">
                <img src={i} alt="" />
              </a>
              <a href="https://www.youtube.com/" className="social">
                <img src={y} alt="" />
              </a>
            </div>
            <ul className={inMenuOpen ? "active" : ""}>
              <li onClick={this.toggleNav}>
                <NavLink to="/" exact activeClassName="active" className="nav__ct">
                  <div className="nav__icon"></div>
                  <div className="nav__text">{_t("For CT centers")}</div>
                </NavLink>
              </li>
              <li onClick={this.toggleNav}>
                <NavLink to="/for-doctors" activeClassName="active" className="nav__doc">
                  <div className="nav__icon"></div>
                  <div className="nav__text">{_t("For doctors")}</div>
                </NavLink>
              </li>
              <li onClick={this.toggleNav}>
                <NavLink to="/premium" activeClassName="active" className="nav__premium">
                  <div className="nav__icon"></div>
                  <div className="nav__text">{_t("Premium Offer")}</div>
                </NavLink>
              </li>
              <li>
                <FormControl className="navLang">
                  <Select className="navLang__select" value={lang} onChange={this.langHandler}>
                    <MenuItem value={1} className="header__langItems">
                      {_t("English (EN)")}
                    </MenuItem>
                    <MenuItem value={2} className="header__langItems">
                      {_t("Русский (РУС)")}
                    </MenuItem>
                    <MenuItem value={3} className="header__langItems">
                      {_t("Український (УКР)")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </li>
              <li>
                <Button onClick={this.props.openCtLogInDialog}>{_t("Log in")}</Button>
              </li>

              <button className="nav-close" onClick={this.toggleNav}>
                <span></span>
                <span></span>
              </button>
            </ul>
            <button className="nav-open" onClick={this.toggleNav}>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </nav>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(Header);
