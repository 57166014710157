import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import forDoctors_icon1 from "../../../assets/imgs/forDoctors_icon1.png";
import forDoctors_icon2 from "../../../assets/imgs/forDoctors_icon2.png";
import forDoctors_icon3 from "../../../assets/imgs/forDoctors_icon3.png";
import s3i1 from "../../../assets/imgs/s3i1.png";
import s3i2 from "../../../assets/imgs/s3i2.png";
import s3i3 from "../../../assets/imgs/s3i3.png";
import forDoctorss5 from "../../../assets/imgs/forDoctorss5.png";
import video from "../../../assets/videos/concept2.mp4";
import DialogFind from "../DialogFind";
import DialogRecommend from "../DialogRecommend";
import "./index.scss";
import scrollToTop from "../../../modules/scrollToTop";

class ForDoctors extends PureComponent {
  state = {
    transform: 0,
    isDialogFindOpen: false,
    isDialogRecommendOpen: false,
  };

  componentDidMount() {
    scrollToTop();
    window.addEventListener("scroll", this.scrollHandler, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollHandler);
  }

  scrollHandler = () => {
    this.setState({
      transform: window.scrollY / 7,
    });
  };

  closeDialogFind = () => {
    this.setState({
      isDialogFindOpen: !this.state.isDialogFindOpen,
    });
  };

  closeDialogRecommend = () => {
    this.setState({
      isDialogRecommendOpen: !this.state.isDialogRecommendOpen,
    });
  };

  render() {
    const { t: _t } = this.props;
    const { transform, isDialogFindOpen, isDialogRecommendOpen } = this.state;
    return (
      <div className="forDoctors">
        <DialogFind isDialogFindOpen={isDialogFindOpen} closeDialogFind={this.closeDialogFind} />
        <DialogRecommend
          isDialogRecommendOpen={isDialogRecommendOpen}
          closeDialogRecommend={this.closeDialogRecommend}
        />
        <div className="section1" style={{ transform: `translate3d(0, ${transform}px, 0)` }}>
          <div className="container">
            <div className="section1__wrap">
              <h1>{_t("X-RAY CENTER")}</h1>
              <h6>
                {_t(
                  "If you are a doctor and regularly refer patients for different types of diagnostic tests, you can find a digital research center with a convenient location for you and use a modern communication system.",
                )}
              </h6>
              <p>
                {_t(
                  "You can find research centers for dental as well as for general medical practice using the search system",
                )}
              </p>
              <p>{_t("Use the search to register and log in")}</p>
              <div className="vbtn" onClick={this.closeDialogFind}>
                <p>{_t("FIND DIAGNOSTIC RESEARCH CENTER")}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="simplePaper">
          <div className="section2">
            <div className="container">
              <div className="first">
                {[
                  {
                    id: 1,
                    pic: forDoctors_icon1,
                    title: _t("Data storage"),
                    text: _t("Online access to data from any device"),
                  },
                  {
                    id: 2,
                    pic: forDoctors_icon2,
                    title: _t("Modern interface"),
                    text: _t("Quick Search Patient Cards Feedback from the Research Center"),
                  },
                  {
                    id: 3,
                    pic: forDoctors_icon3,
                    title: _t("Download links"),
                    text: _t("External download links by unregistered users"),
                  },
                ].map(el => (
                  <div className="first__item" key={el.id}>
                    <img src={el.pic} alt="" />
                    <h6>{el.title}</h6>
                    <p>{el.text}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="section3">
            <div className="container">
              <h2>{_t("How our platform works:")}</h2>
              <div className="first">
                {[
                  {
                    id: 1,
                    pic: s3i1,
                    text: _t("Find a digital diagnostic research center through our database. Register quickly."),
                  },
                  {
                    id: 2,
                    pic: s3i2,
                    text: _t(
                      "In the \"Referrals\" section, you create a referral in accordance with the existing form. Give the document to the patient, according to which he needs to come to the indicated address and undergo the necessary tests.",
                    ),
                  },
                  {
                    id: 3,
                    pic: s3i3,
                    text: _t(
                      "Receive a notification on email or Telegram about getting test results for the patient to your account. Log in to your account and download files received from the research center.",
                    ),
                  },
                ].map(el => (
                  <div className="first__item" key={el.id}>
                    <div className="first__pic">{el.id}</div>
                    <div className="first__text">{el.text}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="section4">
            <div className="container">
              <h2>
                {_t(
                  "If the Center for Digital Diagnostic Research with which you collaborate doesn't have such communication system, recommend them our platform.",
                )}
              </h2>
              <div className="vbtn" onClick={this.closeDialogRecommend}>
                <p>{_t("RECOMMEND THIS PLATFORM TO YOUR CENTER")}</p>
              </div>
            </div>
          </div>
          <div className="section5">
            <img src={forDoctorss5} alt="" />
            <div className="container">
              <div className="section5__wrap">
                <h2>{_t("Find your diagnostic center on our platform")}</h2>
                <div className="vbtn" onClick={this.closeDialogFind}>
                  <p>{_t("FIND DIAGNOSTIC RESEARCH CENTER")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="section6">
            <div className="container">
              <h2>{_t("You can see how our interface works watching the video below")}</h2>
              <video src={video} autoPlay muted loop></video>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ForDoctors.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(ForDoctors);
