import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { Link } from "react-router-dom";
import { Dialog, Toolbar, AppBar, IconButton } from "@material-ui/core";
import search_icon from "../../../assets/imgs/search_icon.png";
import search_pic from "../../../assets/imgs/search_pic.png";
import DialogRegister from "../DialogRegister";
import DialogPrice from "../DialogPrice";
import "./index.scss";

class DialogFind extends PureComponent {
  state = {
    isRegisterOpen: false,
    isPriceOpen: false,
  };
  closeDialogRegister = () => {
    this.setState({
      isRegisterOpen: !this.state.isRegisterOpen,
    });
  };
  closeDialogPrice = () => {
    this.setState({
      isPriceOpen: !this.state.isPriceOpen,
    });
  };
  render() {
    const { t: _t } = this.props;
    const { isRegisterOpen, isPriceOpen } = this.state;
    return (
      <Dialog
        fullScreen
        open={this.props.isDialogFindOpen}
        onClose={this.props.closeDialogFind}
        aria-labelledby="dialog-find"
        className="dialog-find simpleDialog"
      >
        <DialogRegister isRegisterOpen={isRegisterOpen} closeDialogRegister={this.closeDialogRegister} />
        <DialogPrice isPriceOpen={isPriceOpen} closeDialogPrice={this.closeDialogPrice} />
        <AppBar className="dialog-find-appBar">
          <Toolbar className="dialog-find-toolBar">
            <div className="dialog-find-title">{_t("Find a Digital Diagnostic Center")}</div>
            <IconButton
              color="inherit"
              style={{ fontSize: "14px" }}
              onClick={this.props.closeDialogFind}
              aria-label="close"
            >
              {_t("Close")}
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className="dialog-find-content">
          <div className="dialog-find-search">
            <div className="dialog-find-search-wrap">
              <div className="dialog-find-search-icon">
                <img src={search_icon} alt="" />
              </div>
              <div className="dialog-find-search-text">{_t("Search:")}</div>
            </div>
            <div className="dialog-find-search-input">
              <input type="text" />
            </div>
          </div>
          <div className="dialog-find-items">
            <div className="dialog-find-item">
              <div className="dialog-find-item-pic">
                <img src={search_pic} alt="" />
              </div>
              <div className="dialog-find-item-infoWrap">
                <div className="dialog-find-item-info">
                  <h6 className="dialog-find-item-title">{_t("СT Super Center")}</h6>
                  <div className="dialog-find-item-info-item">
                    <span>{_t("Country")}:</span>
                    <span>{_t("Ukraine")}</span>
                  </div>
                  <div className="dialog-find-item-info-item">
                    <span>{_t("City")}:</span>
                    <span>{_t("Zaporizhia")}</span>
                  </div>
                  <div className="dialog-find-item-info-item">
                    <span>{_t("Address")}:</span>
                    <span>{_t("Soborniy Ave, 34")}</span>
                  </div>
                  <div className="dialog-find-item-info-item">
                    <span>{_t("Tel/Mob")}:</span>
                    <span>+38 097 777 54 43</span>
                  </div>
                  <div className="dialog-find-item-info-item">
                    <span>{_t("Email Address")}:</span>
                    <span>{_t("scenter@gmail.com")}</span>
                  </div>
                </div>
                <div className="dialog-find-item-btns">
                  <div className="dialog-find-item-btn" onClick={this.closeDialogPrice}>
                    {_t("VIEW PRICE")}
                  </div>
                  <div className="dialog-find-item-btn" onClick={this.closeDialogRegister}>
                    {_t("REGISTER")}
                  </div>
                  <Link to="/login" className="dialog-find-item-btn">
                    {_t("LOG IN")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

DialogFind.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(DialogFind);
