import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import ConfirmEmailForm from "./ConfirmEmailForm";
import { Dialog, Toolbar, AppBar, IconButton, Paper } from "@material-ui/core";
import "./index.scss";

class ConfirmEmail extends PureComponent {
  render() {
    const { t: _t, isConfirmEmailOpen, openConfirmEmailDialog } = this.props;
    return (
      <Dialog
        fullScreen
        open={isConfirmEmailOpen}
        onClose={openConfirmEmailDialog}
        aria-labelledby="dialog-confirmEmail"
        className="dialog-confirmEmail"
      >
        <AppBar className="dialog-confirmEmail-appBar">
          <Toolbar className="dialog-confirmEmail-toolBar">
            <IconButton
              color="inherit"
              style={{ fontSize: "14px" }}
              onClick={openConfirmEmailDialog}
              aria-label="close"
            >
              {_t("Close")}
            </IconButton>
          </Toolbar>
        </AppBar>
        <Paper className="dialog-confirmEmail-paper">
          <ConfirmEmailForm />
        </Paper>
      </Dialog>
    );
  }
}

ConfirmEmail.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(ConfirmEmail);
