import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { login, testLogin } from "../../actions";
import propTypes from "prop-types";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import { TextField, Button } from "@material-ui/core";
import ForgotPassword from "../../layouts/LoginLayout/ForgotPassword";
import "./index.scss";

class CtLogInForm extends Component {
  state = {
    isForgotPasswordDialog: false,
  };
  CtLogInSchema = () => {
    const { t: _t } = this.props;
    return Yup.object().shape({
      email: Yup.string()
        .email()
        .required(_t("Mandatory input")),
      password: Yup.string().required(_t("Mandatory input")),
    });
  };

  login = ({ email, password }) => {
    this.props.login(email, password);
  };

  toggleForgotPasswordDialog = () => {
    this.setState({ isForgotPasswordDialog: !this.state.isForgotPasswordDialog });
  };

  render() {
    const { t: _t, networkErrors } = this.props;
    return (
      <div className="ctLogInForm">
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={this.CtLogInSchema()}
          onSubmit={values => {
            this.login(values);
          }}
          render={({ errors }) => (
            <Form>
              <div className="ctLogInForm__bg">{_t("Log In")}</div>
              <div className="ctLogInForm__wrap">
                <Field
                  name="email"
                  render={({ field }) => (
                    <TextField
                      type="string"
                      label={_t("Email Address")}
                      className="ctLogInForm__input"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      error={!!errors.email}
                      helperText={errors.email}
                      {...field}
                    />
                  )}
                />
                <Field
                  name="password"
                  render={({ field }) => (
                    <TextField
                      type="password"
                      label={_t("Password")}
                      className="ctLogInForm__input"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      error={!!errors.password}
                      helperText={errors.password}
                      {...field}
                    />
                  )}
                />
                <div style={{ maxWidth: 300 }}>
                  {networkErrors && networkErrors.length > 0 && <div>{_t("Wrong log in or password!")}</div>}
                </div>
                {/* <Button type="submit" variant="contained" color="primary" className="btn ctLogInForm-btn">
                  {_t("Log in")}
                </Button> */}
                <div className="testBtn" onClick={() => this.props.testLogin("owner")}>
                  <Button variant="contained" color="primary" className="btn ctLogInForm-btn">
                    {_t("Log in")}
                  </Button>
                </div>
                <p className="ctLogInForm-btn-forgot" onClick={this.toggleForgotPasswordDialog}>
                  {_t("Forgot your password?")}
                </p>
              </div>
            </Form>
          )}
        />
        <ForgotPassword isShow={this.state.isForgotPasswordDialog} onClose={this.toggleForgotPasswordDialog} />
      </div>
    );
  }
}

CtLogInForm.propTypes = {
  t: propTypes.func.isRequired,
};

const mapStateToProps = ({ login, profile }) => ({
  networkErrors: login.errors,
});

const mapDispatchToProps = {
  login,
  testLogin,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(CtLogInForm));
