import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { Dialog, Toolbar, AppBar, IconButton } from "@material-ui/core";
import DoctorRegForm from "../../../components/DoctorRegForm";
import "./index.scss";

class DialogRegister extends PureComponent {
  render() {
    const { t: _t, isRegisterOpen, closeDialogRegister } = this.props;
    return (
      <Dialog
        fullScreen
        open={isRegisterOpen}
        onClose={closeDialogRegister}
        aria-labelledby="dialog-register"
        className="dialog-register"
      >
        <AppBar className="dialog-register-appBar">
          <Toolbar className="dialog-register-toolBar">
            <IconButton
              color="inherit"
              style={{ fontSize: "14px" }}
              onClick={this.props.closeDialogRegister}
              aria-label="close"
            >
              {_t("Close")}
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className="dialog-register-wrap">
          <DoctorRegForm />
        </div>
      </Dialog>
    );
  }
}

DialogRegister.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(DialogRegister);
