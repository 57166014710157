import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { API } from "../../../modules/Api";
import { NavLink } from "react-router-dom";
// import Logger from "../modules/Logger";
import { push } from "connected-react-router";
import propTypes from "prop-types";
import logoArab from "../../../assets/img/logoArab.png";
import "./index.scss";

class ChangeEmailSuccess extends PureComponent {
  componentDidMount() {
    this.changeEmailSuccess();
  }

  changeEmailSuccess = () => {
    API.put(`/user/change_email/${this.props.match.params.token}`).then(
      response => {
        console.log(response);
        setTimeout(() => {
          this.props.history.push("/cards");
        }, 5000);
      },
      error => {
        console.log(error);
      },
    );
  };

  render() {
    return (
      <div className="confirm">
        <table cellPadding="0" cellSpacing="0" border="0" id="mainContent">
          <tr>
            <td className="container">
              <div className="content">
                <div className="logoBox">
                  <img src={logoArab} className="logoBox-img img-responsive" inline alt="" />
                </div>
                <table role="presentation" className="main">
                  <tbody>
                    <tr>
                      <td className="wrapper">
                        <table role="presentation" border="0" cellPadding="0" cellSpacing="0">
                          <tbody>
                            <tr>
                              <td>
                                <p className="fw700">
                                  The change email is successful, now you will be redirected, if this did not happen,
                                  click on the button
                                </p>
                                <div className="btn-container">
                                  <NavLink to="/cards" class="btn btn-primary">
                                    Back
                                  </NavLink>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </table>
      </div>
    );
  }
}

ChangeEmailSuccess.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(ChangeEmailSuccess);
