import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { Dialog, DialogActions, Button } from "@material-ui/core";
import { API } from "../../../modules/Api";
import "./index.scss";
const tableItems = [
  {
    id: 1,
    name: "Panoramic X-ray (OPG)",
    price: 220,
  },
  {
    id: 2,
    name: "Cephalometric X-ray (TRG)",
    price: 2220,
  },
  {
    id: 3,
    name: "Segment (1-2 teeth) 4x5 cm extent of test",
    price: 13,
  },
  {
    id: 4,
    name: "Jaw 10x5 cm extent of test",
    price: 0,
  },
  {
    id: 5,
    name: "Jaw 10x5 cm extent of test",
    price: 10,
  },
  {
    id: 6,
    name: "Jaw 10x5 cm extent of test",
    price: 20,
  },
];

class DialogPrice extends PureComponent {
  state = {
    // persons: [],
    currentPage: 1,
    postsPerPage: 5,
    prevDisabled: false,
    nextDisabled: false,
  };
  componentDidMount() {
    // API.get(`https://jsonplaceholder.typicode.com/posts`).then(res => {
    //   const persons = res.data;
    //   this.setState({ persons });
    // });
    if (this.state.currentPage <= 1) {
      this.setState({
        prevDisabled: true,
      });
    }
  }

  // componentDidUpdate() {
  //   if (this.state.postsPerPage == -1) {
  //     this.setState({
  //       nextDisabled: true,
  //       prevDisabled: true,
  //     });
  //   }
  //   this.disablingNav();
  // }

  handlePostPerPage = e => {
    this.setState({
      postsPerPage: e.target.value,
    });
  };

  disablingNav = () => {
    if (this.state.currentPage <= 1) {
      this.setState({
        prevDisabled: true,
      });
    } else {
      this.setState({
        prevDisabled: false,
      });
    }
    if (this.state.currentPage >= Math.ceil(tableItems.length / this.state.postsPerPage)) {
      this.setState({
        nextDisabled: true,
      });
    } else {
      this.setState({
        nextDisabled: false,
      });
    }
  };
  prevPage = () => {
    this.setState({ currentPage: this.state.currentPage - 1 }, () => {
      console.log(this.state.currentPage);
      this.disablingNav();
    });
  };
  nextPage = () => {
    this.setState({ currentPage: this.state.currentPage + 1 }, () => {
      console.log(this.state.currentPage);
      this.disablingNav();
    });
  };

  render() {
    const { t: _t, isPriceOpen, closeDialogPrice } = this.props;
    const { postsPerPage, persons, currentPage, prevDisabled, nextDisabled } = this.state;

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = tableItems.slice(indexOfFirstPost, indexOfLastPost);

    return (
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isPriceOpen}
        onClose={closeDialogPrice}
        aria-labelledby="dialog-price"
        className="dialog-price"
      >
        <div className="dialog-price-wrap">
          <h6 className="dialog-price-item-title">{_t("СT Super Center")}</h6>
          <div className="dialog-price-item-info-item">
            <span>{_t("Country")}:</span>
            <span>{_t("Ukraine")}</span>
          </div>
          <div className="dialog-price-item-info-item">
            <span>{_t("City")}:</span>
            <span>{_t("Zaporizhia")}</span>
          </div>
          <div className="dialog-price-item-info-item">
            <span>{_t("Address")}:</span>
            <span>{_t("Soborniy Ave, 34")}</span>
          </div>
          <div className="dialog-price-item-info-item">
            <span>{_t("Tel/Mob")}:</span>
            <span>+38 097 777 54 43</span>
          </div>
          <div className="dialog-price-item-info-item">
            <span>{_t("Email Address")}:</span>
            <span>{_t("scenter@gmail.com")}</span>
          </div>
        </div>
        <div className="dialog-price-table">
          {/* {currentPosts.map(el => (
            <p key={el.id}>{el.id}</p>
          ))} */}
          {currentPosts.map(el => (
            <div className="dialog-price-row" key={el.id}>
              <div className="dialog-price-cell dialog-price-cell_name">{el.name}</div>
              <div className="dialog-price-cell dialog-price-cell_price">{el.price}</div>
              <div className="dialog-price-cell dialog-price-cell_currency">$</div>
            </div>
          ))}
          <div className="dialog-price-pagination">
            <div className="dialog-price-perPage">
              <label htmlFor="rowsPerPage">Rows per page:</label>
              <select name="rowsPerPage" id="rowsPerPage" onChange={this.handlePostPerPage} value={postsPerPage}>
                <option value="5">5</option>
                <option value="15">15</option>
                <option value={tableItems.length}>All</option>
              </select>
            </div>
            <div className="dialog-price-nums">of {tableItems.length}</div>
            <div className="dialog-price-nav">
              <span className={`${prevDisabled && "disabled"}`} onClick={this.prevPage}>
                &lt;
              </span>
              <span className={`${nextDisabled && "disabled"}`} onClick={this.nextPage}>
                &gt;
              </span>
            </div>
          </div>
        </div>
        <DialogActions>
          <Button onClick={closeDialogPrice} color="primary">
            {_t("Close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DialogPrice.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(DialogPrice);
