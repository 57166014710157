import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import TermsOfUse from "../../layouts/LoginLayout/Footer/Dialogs/Use";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
// import { API } from "../../modules/Api";
import "./index.scss";

class DoctorRegForm extends Component {
  state = {
    isOpenTermsOfUse: false,
    termsCheckbox: true,
    loading: false,
    thankYou: false,
  };

  registerSchema = () => {
    const { t: _t } = this.props;
    return Yup.object().shape({
      name: Yup.string().required(_t("Required")),
      clinic: Yup.string().required(_t("Required")),
      phone_number: Yup.string().required(_t("Required")),
      email: Yup.string()
        .email(_t("Invalid email"))
        .required(_t("Required")),
      password: Yup.string()
        .required(_t("Required"))
        .min(8, "Password is too short - should be 8 chars minimum."),
      password2: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required(_t("Required")),
    });
  };

  register = form => {
    if (!this.state.termsCheckbox) {
      return false;
    }

    this.setState({
      loading: true,
    });

    console.warn("Данные формы-------------", form);
    const thankYou = () => {
      this.setState({
        thankYou: true,
      });
    };

    setTimeout(thankYou, 2000);
    // let registerData = {
    //   email: form.email,
    //   password: form.password,
    //   name: form.name,
    //   clinic: form.clinic,
    //   phone_number: form.phone_number,
    //   shipping_address: form.shipping_address,
    //   country: form.country,
    // };
    // API.post("/user/register", registerData).then(
    //   response => {
    //     console.log(response);
    //     this.props.registerChangePage(form.email);
    //   },
    //   err => {
    //     console.log(err);
    //   },
    // );
  };
  termsOfUseDialogHandler = () => {
    this.setState({
      isOpenTermsOfUse: !this.state.isOpenTermsOfUse,
    });
  };
  termsCheckboxHandler = () => {
    this.setState({
      termsCheckbox: !this.state.termsCheckbox,
    });
  };

  render() {
    const { t: _t } = this.props;
    const { isOpenTermsOfUse, termsCheckbox, loading, thankYou } = this.state;
    return (
      <div>
        <div className={`doctorRegFormThanks ${thankYou ? "afterSend" : ""}`}>
          <h6>{_t("Thank you for registration on our platform!")}</h6>
          <p>{_t("We have sent you a confirmation email to your email address you provided during registration")}</p>
          <div className="doctorRegFormThanks__mail">ivcanivanov@gmail.com </div>
          <p>{_t("If you did not receive the email, just in case, check:")}</p>
          <ul>
            <li>{_t("there are no mistakes in the email address")}</li>
            <li>{_t("did the email fall into the spam folder")}</li>
          </ul>
          <p>
            {_t(
              "If you have any questions regarding registration or access to your personal page, write to xray@gmail.com, we will understand and definitely help.",
            )}
          </p>
        </div>
        <div className={`doctorRegForm ${thankYou ? "sent" : ""}`}>
          <div className={`doctorRegForm__overlay ${loading ? "active" : ""}`}>
            <CircularProgress />
          </div>
          <Formik
            initialValues={{
              name: "",
              clinic: "",
              phone_number: "",
              email: "",
              password: "",
              password2: "",
            }}
            validationSchema={this.registerSchema}
            onSubmit={this.register}
            render={({ errors }) => (
              <Form>
                <div className="doctorRegForm__bg">{_t("Doctor registration")}</div>
                <div className="doctorRegForm__wrap">
                  <Field
                    name="name"
                    render={({ field }) => (
                      <TextField
                        type="string"
                        className="doctorRegForm-input"
                        label="Doctor's Name"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        error={!!errors.name}
                        helperText={errors.name}
                        {...field}
                      />
                    )}
                  />
                  <Field
                    name="clinic"
                    render={({ field }) => (
                      <TextField
                        type="string"
                        className="doctorRegForm-input"
                        label="Clinic"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        error={!!errors.clinic}
                        helperText={errors.clinic}
                        {...field}
                      />
                    )}
                  />

                  <Field
                    name="email"
                    render={({ field }) => (
                      <TextField
                        type="email"
                        className="doctorRegForm-input"
                        label="Email Address"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        error={!!errors.email}
                        helperText={errors.email}
                        {...field}
                      />
                    )}
                  />
                  <Field
                    name="phone_number"
                    render={({ field }) => (
                      <TextField
                        type="string"
                        className="doctorRegForm-input"
                        label="Tel/Mob"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        error={!!errors.phone_number}
                        helperText={errors.phone_number}
                        {...field}
                      />
                    )}
                  />
                  <Field
                    name="password"
                    render={({ field }) => (
                      <TextField
                        type="password"
                        className="doctorRegForm-input"
                        label="Password"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        error={!!errors.password}
                        helperText={errors.password}
                        {...field}
                      />
                    )}
                  />
                  <Field
                    name="password2"
                    render={({ field }) => (
                      <TextField
                        type="password"
                        className="doctorRegForm-input"
                        label="Password again"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        error={!!errors.password2}
                        helperText={errors.password2}
                        {...field}
                      />
                    )}
                  />
                  <div className="doctorRegForm-terms">
                    <FormControlLabel
                      control={
                        <Checkbox checked={termsCheckbox} onChange={this.termsCheckboxHandler} color="primary" />
                      }
                      label={_t("I agree to the terms")}
                    />
                    <Button className="doctorRegForm-terms-btn" onClick={this.termsOfUseDialogHandler}>
                      {_t("Terms of Use")}
                    </Button>
                  </div>
                  <Button
                    type="submit"
                    variant="contained"
                    className={`doctorRegForm-btn ${!termsCheckbox ? "disabled" : ""}`}
                  >
                    {_t("Register")}
                  </Button>
                </div>
              </Form>
            )}
          />
          <Dialog
            fullWidth
            maxWidth={"md"}
            open={isOpenTermsOfUse}
            onClose={this.termsOfUseDialogHandler}
            aria-labelledby="dialog-termsOfUse"
          >
            <DialogTitle id="dialog-termsOfUse">{_t("Terms of Use")}</DialogTitle>
            <DialogContent className="dialog-text">
              <TermsOfUse />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.termsOfUseDialogHandler} color="primary">
                {_t("Close")}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

DoctorRegForm.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(DoctorRegForm);
