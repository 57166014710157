import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import "./index.scss";
import login_avatar from "../../../assets/imgs/login_avatar.png";
import Build_Icon_1 from "../../../assets/imgs/Build_Icon_1.png";
import scrollToTop from "../../../modules/scrollToTop";
import DoctorLoginForm from "../../../components/DoctorLoginForm";

class Login extends PureComponent {
  state = {
    transform: 0,
    isMenu: false,
    bg: "bg1",
    bg_gradient: "blue",
  };

  componentDidMount() {
    scrollToTop();
    window.addEventListener("scroll", this.scrollHandler, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollHandler);
  }

  scrollHandler = () => {
    this.setState({
      transform: window.scrollY / 4,
    });
  };

  hideMenu = () => {
    this.setState({
      isMenu: !this.state.isMenu,
    });
  };

  changeGradient = gradient => {
    this.setState({
      bg_gradient: gradient,
    });
  };

  radio = bg => {
    this.setState({
      bg: bg,
    });
  };

  render() {
    const { t: _t } = this.props;
    const { bg, bg_gradient, isMenu } = this.state;

    return (
      <div className={`login ${bg_gradient} ${bg}`}>
        <div className="login__menu">
          <div className="login__menuBtn" onClick={this.hideMenu}>
            <img src={Build_Icon_1} alt="" />
          </div>
          <div className={`login__menuWrap ${!isMenu ? "active" : ""}`}>
            <h5>{_t("Background selection")}</h5>
            <div className="login__gradients">
              {["blue", "green", "red", "violet"].map(color => (
                <div
                  key={color}
                  className={`login__gradient ${this.state.bg_gradient === color ? "active" : ""}`}
                  onClick={() => {
                    this.changeGradient(color);
                  }}
                />
              ))}
            </div>
            <div className="login__bgs">
              {[{ id: 1, bg: "bg1" }, { id: 2, bg: "bg2" }, { id: 3, bg: "bg3" }].map(bg => (
                <div
                  key={bg.id}
                  className="login__bg"
                  onClick={() => {
                    this.radio(bg.bg);
                  }}
                >
                  <input type="radio" name="bg1" id={bg.bg} />
                  <label htmlFor={bg.bg}>
                    <img src={require(`../../../assets/imgs/login_bg_small_${bg.id}.jpg`)} alt="" />
                    <p>{_t(`Background ${bg.id}`)}</p>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <img className="login__avatar" src={login_avatar} alt="" />
        <h1 className="login__title">{_t("X-RAY CENTER")}</h1>
        <p className="login__subtitle">{_t("Digital Diagnostic Center")}</p>
        <DoctorLoginForm />
      </div>
    );
  }
}

Login.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(Login);
