import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { Dialog, Toolbar, AppBar, IconButton, TextField, Button } from "@material-ui/core";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import "./index.scss";

class DialogOnline extends PureComponent {
  onlineSchema = () => {
    const { t: _t } = this.props;
    return Yup.object().shape({
      emailAddress: Yup.string()
        .email(_t("Invalid email"))
        .required(_t("Required")),
    });
  };

  onlineSend = form => {
    console.warn("Данные Online формы----------------------", form);
  };
  render() {
    const { t: _t } = this.props;
    return (
      <Dialog
        fullScreen
        open={this.props.isDialogOnlineOpen}
        onClose={this.props.closeDialogOnline}
        aria-labelledby="dialog-online"
        className="dialog-online"
      >
        <AppBar className="dialog-online-appBar">
          <Toolbar className="dialog-online-toolBar">
            <div className="dialog-online-title">{_t("Contact Us")}</div>
            <IconButton
              color="inherit"
              style={{ fontSize: "14px" }}
              onClick={this.props.closeDialogOnline}
              aria-label="close"
            >
              {_t("Close")}
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className="dialog-online-content">
          <Formik
            initialValues={{
              onlineMessage: "",
              emailAddress: "",
            }}
            validationSchema={this.onlineSchema}
            onSubmit={this.onlineSend}
            render={({ errors, values }) => (
              <Form className="dialog-online-form">
                <p>{_t("Write us a message and we will respond you as soon as possible.")}</p>
                <div className="dialog-online-wrap">
                  <Field
                    name="onlineMessage"
                    render={({ field }) => (
                      <TextField
                        margin="dense"
                        multiline
                        rows="5"
                        rowsMax="40"
                        {...field}
                        className="dialog-online-textarea"
                      />
                    )}
                  />
                  <div className="dialog-online-icon" />
                </div>

                <Field
                  name="emailAddress"
                  render={({ field }) => (
                    <TextField
                      type="email"
                      label="Email Address"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      className="dialog-online-email"
                      error={!!errors.emailAddress}
                      helperText={errors.emailAddress}
                      {...field}
                    />
                  )}
                />
                <Button type="submit" className="dialog-online-send">
                  {_t("Send")}
                </Button>
              </Form>
            )}
          />
        </div>
      </Dialog>
    );
  }
}

DialogOnline.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(DialogOnline);
