import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { Route, Switch } from "react-router";
import HomePage from "./HomePage";
import ForDoctors from "./ForDoctors";
import Premium from "./Premium";
import Login from "./Login";
import RegisterSteps from "./RegisterSteps";
import Header from "./Header";
import Footer from "./Footer";
import ConfirmPage from "./ConfirmPage";
import ChangeEmailSuccess from "./ChangeEmailSuccess";
import CtLogin from "./Header/CtLogin";

import "./index.scss";

class LoginLayout extends PureComponent {
  state = {
    isCtLogInOpen: false,
  };

  openCtLogInDialog = () => {
    this.setState({ isCtLogInOpen: !this.state.isCtLogInOpen });
  };

  render() {
    const { isCtLogInOpen } = this.state;
    return (
      <div className="loginLayout">
        <Header openCtLogInDialog={this.openCtLogInDialog} />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/sign_up/:token" component={ConfirmPage} />
          <Route exact path="/change_email/:token" component={ChangeEmailSuccess} />
          <Route exact path="/for-doctors" component={ForDoctors} />
          <Route exact path="/premium" component={Premium} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={RegisterSteps} />
        </Switch>
        <Footer />
        <CtLogin isCtLogInOpen={isCtLogInOpen} openCtLogInDialog={this.openCtLogInDialog} />
      </div>
    );
  }
}

LoginLayout.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(LoginLayout);
