import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import premium_icon from "../../../assets/imgs/premium__icon.png";
import premium_s1 from "../../../assets/imgs/premium_s1.png";
import premium_s2 from "../../../assets/imgs/premium_s2.png";
import premium_s3 from "../../../assets/imgs/premium_s3.png";
import premium_s4 from "../../../assets/imgs/premium_s4.png";
import premium_s5 from "../../../assets/imgs/premium_s5.png";
import premium_icon1 from "../../../assets/imgs/premium_icon1.png";
import premium_icon2 from "../../../assets/imgs/premium_icon2.png";
import premium_icon3 from "../../../assets/imgs/premium_icon3.png";
import premium_icon4 from "../../../assets/imgs/premium_icon4.png";
import premium_icon5 from "../../../assets/imgs/premium_icon5.png";
import premium_icon6 from "../../../assets/imgs/premium_icon6.png";
import premium_icon7 from "../../../assets/imgs/premium_icon7.png";
import ConfirmEmail from "../ConfirmEmail";
import DialogOnline from "../DialogOnline";
import "./index.scss";
import scrollToTop from "../../../modules/scrollToTop";

class Premium extends PureComponent {
  state = {
    transform: 0,
    isDialogFindOpen: false,
    isConfirmEmailOpen: false,
    isOpenContact: false,
  };

  componentDidMount() {
    scrollToTop();
    window.addEventListener("scroll", this.scrollHandler, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollHandler);
  }

  scrollHandler = () => {
    this.setState({
      transform: window.scrollY / 7,
    });
  };

  openConfirmEmailDialog = () => {
    this.setState({
      isConfirmEmailOpen: !this.state.isConfirmEmailOpen,
    });
  };
  contactDialogHandler = () => {
    this.setState({
      isOpenContact: !this.state.isOpenContact,
    });
  };

  render() {
    const { t: _t } = this.props;
    const { transform, isConfirmEmailOpen, isOpenContact } = this.state;
    return (
      <div className="premium">
        <DialogOnline isDialogOnlineOpen={isOpenContact} closeDialogOnline={this.contactDialogHandler} />
        <ConfirmEmail isConfirmEmailOpen={isConfirmEmailOpen} openConfirmEmailDialog={this.openConfirmEmailDialog} />
        <div className="section1" style={{ transform: `translate3d(0, ${transform}px, 0)` }}>
          <div className="container">
            <div className="section1__wrap">
              <h1>{_t("X-RAY CENTER")}</h1>
              <h6>
                {_t(
                  "If you are the owner or administrator of a diagnostic center, you have tried our platform and you liked it, we can make you the Premium Offer",
                )}
              </h6>
              <div className="section1__imgs">
                <img src={premium_icon} alt="" />
                <img src={premium_icon} alt="" />
                <img src={premium_icon} alt="" />
              </div>
              <p>{_t("We are ready to develop a platform for you!!!")}</p>
              <div className="vbtn" onClick={this.contactDialogHandler}>
                <p>{_t("CONTACT US")}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="simplePaper">
          <div className="section2">
            <div className="container">
              <h2>{_t("The development of the web platform on the customer's instructions includes:")}</h2>
            </div>
            <div className="first">
              {[
                {
                  id: 1,
                  pic: premium_s1,
                  title: _t("Interface personalization"),
                  text: _t("Interface changes according to your wishes and business processes"),
                },
                {
                  id: 2,
                  pic: premium_s2,
                  title: _t("Unique design"),
                  text: _t("Interface design is developed personally for the customer"),
                },
                {
                  id: 3,
                  pic: premium_s3,
                  title: _t("Limited database access"),
                  text: _t("Medical data is stored on your server and only the customer has access to it"),
                },
                {
                  id: 4,
                  pic: premium_s4,
                  title: _t("Administration"),
                  text: _t(
                    "Administration and support of the platform from the developer throughout the entire period of the using web resource",
                  ),
                },
                {
                  id: 5,
                  pic: premium_s5,
                  title: _t("Additional modules"),
                  text: _t("Appointment Log, CT viewer and more"),
                },
              ].map(el => (
                <div className="first__item" key={el.id}>
                  <img src={el.pic} alt="" />
                  <h6>{el.title}</h6>
                  <p>{el.text}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="section3">
            <div className="container">
              <h2>
                {_t(
                  "If you haven't tried to work with our web platform, try it now. Registration and creation of the first 20 cards are free.",
                )}
              </h2>
              <div className="section3__icons">
                {[
                  { id: 1, pic: premium_icon1 },
                  { id: 2, pic: premium_icon2 },
                  { id: 3, pic: premium_icon3 },
                  { id: 4, pic: premium_icon4 },
                  { id: 5, pic: premium_icon5 },
                  { id: 6, pic: premium_icon6 },
                  { id: 7, pic: premium_icon7 },
                  { id: 8, pic: premium_icon1 },
                  { id: 9, pic: premium_icon2 },
                  { id: 10, pic: premium_icon3 },
                  { id: 11, pic: premium_icon4 },
                  { id: 12, pic: premium_icon5 },
                  { id: 13, pic: premium_icon6 },
                  { id: 14, pic: premium_icon7 },
                ].map(el => (
                  <img src={el.pic} alt="" key={el.id} />
                ))}
              </div>
              <div className="vbtn" onClick={this.openConfirmEmailDialog}>
                <p>{_t("CREATE YOUR VIRTUAL DIAGNOSTIC CENTER")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Premium.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(Premium);
