import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import "./index.scss";

class Сookies extends PureComponent {
  render() {
    const { t: _t } = this.props;
    return (
      <div className="footerModal">
        <h2>{_t("Cookie policy")}</h2>
        <p>
          {_t(
            "This cookie policy (‘Policy’) describes what cookies are and how Dentin corporation (‘Dentin corporation’, ‘we’, ‘us’ or ‘our’) uses them on the clear-in.com website and any of its products or services (collectively, ‘Website’ or ‘Services’). You should read this Policy so you can understand what type of cookies we use, the information we collect using cookies and how that information is used. It also describes the choices available to you regarding accepting or declining the use of cookies. For further information on how we use, store and keep your personal data secure, see our Privacy Policy.",
          )}
        </p>
        <h2>{_t("What are cookies?")}</h2>
        <p>
          {_t(
            "Cookies are small pieces of data stored in text files that are saved on your computer or other devices when websites are loaded in a browser. They are widely used to remember you and your preferences, either for a single visit (through a ‘session cookie’) or for multiple repeat visits (using a ‘persistent cookie’).",
          )}
        </p>
        <p>
          {_t(
            "Session cookies are temporary cookies that are used during the course of your visit to the Website, and they expire when you close the web browser.",
          )}
        </p>
        <p>
          {_t(
            "Persistent cookies are used to remember your preferences within our Website and remain on your desktop or mobile device even after you close your browser or restart your computer. They ensure a consistent and efficient experience for you while visiting our Website or using our Services.",
          )}
        </p>
        <p>
          {_t(
            "Cookies may be set by the Website (‘first-party cookies’), or by third parties, such as those who serve content or provide advertising or analytics services on the website (‘third party cookies’). These third parties can recognize you when you visit our website and also when you visit certain other websites.",
          )}
        </p>
        <h2>{_t("What type of cookies do we use?")}</h2>
        <h3>{_t("Necessary cookies")}</h3>
        <p>
          {_t(
            "Cookies may be set by the Website (‘first-party cookies’), or by third parties, such as those who serve content or provide advertising or analytics services on the website (‘third party cookies’). These third parties can recognize you when you visit our website and also when you visit certain other websites.",
          )}
        </p>
        <h3>{_t("Functionality cookies")}</h3>
        <p>
          {_t(
            "Functionality cookies let us operate the Website and our Services in accordance with the choices you make. For example, we will recognize your username and remember how you customized the Website and Services during future visits.",
          )}
        </p>
        <h3>{_t("Analytical cookies")}</h3>
        <p>
          {_t(
            "These cookies enable us and third-party services to collect aggregated data for statistical purposes on how our visitors use the Website. These cookies do not contain personal information such as names and email addresses and are used to help us improve your user experience of the Website.",
          )}
        </p>
        <h3>{_t("Advertising cookies")}</h3>
        <p>
          {_t(
            "Advertising cookies allow us and third parties serve relevant ads to you more effectively and help us collect aggregated audit data, research, and performance reporting for advertisers. They also enable us to understand and improve the delivery of ads to you and know when certain ads have been shown to you.",
          )}
        </p>
        <p>
          {_t(
            "Your web browser may request advertisements directly from ad network servers, these networks can view, edit, or set their own cookies, just as if you had requested a web page from their website.",
          )}
        </p>
        <p>
          {_t(
            "Although we do not use cookies to create a profile of your browsing behavior on third-party sites, we do use aggregate data from third parties to show you relevant, interest-based advertising. We do not provide any personal information that we collect to advertisers.",
          )}
        </p>
        <h3>{_t("Social media cookies")}</h3>
        <p>
          {_t(
            "Third-party cookies from social media sites (such as Facebook, Twitter, etc) let us track social network users when they visit our Website, use our Services or share content, by using a tagging mechanism provided by those social networks.",
          )}
        </p>
        <p>
          {_t(
            "These cookies are also used for event tracking and remarketing purposes. Any data collected with these tags will be used in accordance with our and social networks’ privacy policies. We will not collect or share any personally identifiable information from the use.",
          )}
        </p>
        <h2>{_t("Do we use web beacons or tracking pixels?")}</h2>
        <p>
          {_t(
            "Our emails may contain a ‘web beacon’ (or ‘tracking pixel’) to tell us whether our emails are opened and verify any clicks through to links or advertisements within the email.",
          )}
        </p>
        <p>
          {_t(
            "We may use this information for purposes including determining which of our emails are more interesting to users and to query whether users who do not open our emails wish to continue receiving them.",
          )}
        </p>
        <p>
          {_t(
            "The pixel will be deleted when you delete the email. If you do not wish the pixel to be downloaded to your device, you should read the email in plain text view or with images disabled.",
          )}
        </p>
        <h2>{_t("What are your cookie options?")}</h2>
        <p>
          {_t(
            "If you don't like the idea of cookies or certain types of cookies, you can change your browser's settings to delete cookies that have already been set and to not accept new cookies. To learn more about how to do this or to learn more about cookies, visit internetcookies.org.",
          )}
        </p>
        <h2>{_t("Changes and amendments")}</h2>
        <p>
          {_t(
            "We reserve the right to modify this Policy relating to the Website or Services at any time, effective upon posting of an updated version of this Policy on the Website. When we do we will revise the updated date at the bottom of this page. Continued use of the Website after any such changes shall constitute your consent to such changes.",
          )}
        </p>
        <h2>{_t("Acceptance of this policy")}</h2>
        <p>
          {_t(
            "You acknowledge that you have read this Policy and agree to all its terms and conditions. By using the Website or its Services you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to use or access the Website and its Services.",
          )}
        </p>
      </div>
    );
  }
}

Сookies.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(Сookies);
