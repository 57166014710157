import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@material-ui/core";
import f1f from "../../../assets/images/LoginLayout/f1f.png";
import f1i from "../../../assets/images/LoginLayout/f1i.png";
import f1t from "../../../assets/images/LoginLayout/f1t.png";
import Conditions from "./Dialogs/Conditions";
import Cookies from "./Dialogs/Cookies";
import Use from "./Dialogs/Use";
import ContactUs from "../DialogOnline";
import "./index.scss";

class Footer extends PureComponent {
  state = {
    isOpenTerms: false,
    isOpenTermsOfUse: false,
    isOpenCookies: false,
    isOpenContact: false,
  };

  termsDialogHandler = () => {
    this.setState({
      isOpenTerms: !this.state.isOpenTerms,
    });
  };

  termsOfUseDialogHandler = () => {
    this.setState({
      isOpenTermsOfUse: !this.state.isOpenTermsOfUse,
    });
  };

  cookiesDialogHandler = () => {
    this.setState({
      isOpenCookies: !this.state.isOpenCookies,
    });
  };
  contactDialogHandler = () => {
    this.setState({
      isOpenContact: !this.state.isOpenContact,
    });
  };

  render() {
    const { t: _t } = this.props;
    const { isOpenTerms, isOpenTermsOfUse, isOpenCookies, isOpenContact } = this.state;
    return (
      <footer className="footer">
        <div className="container">
          <nav>
            <ul>
              <li>
                <Button onClick={this.contactDialogHandler}>{_t("Contact Us")}</Button>
              </li>
              <li>
                <Button onClick={this.termsOfUseDialogHandler}>{_t("Terms of Use")}</Button>
              </li>

              <li>
                <Button onClick={this.cookiesDialogHandler}>{_t("Cookie policy")}</Button>
              </li>
              <li>
                <Button onClick={this.termsDialogHandler}>{_t("Terms & Conditions")}</Button>
              </li>
            </ul>
            <div className="socialLinks">
              <a href="#" className="socialLink">
                <img src={f1t} alt="twitter" />
              </a>
              <a href="#" className="socialLink">
                <img src={f1f} alt="facebook" />
              </a>
              <a href="#" className="socialLink">
                <img src={f1i} alt="instagram" />
              </a>
            </div>
          </nav>
        </div>
        <ContactUs isDialogOnlineOpen={isOpenContact} closeDialogOnline={this.contactDialogHandler} />
        <Dialog
          fullWidth
          maxWidth={"md"}
          open={isOpenTerms}
          onClose={this.termsDialogHandler}
          aria-labelledby="dialog-terms"
        >
          <DialogTitle id="dialog-terms">{_t("Terms & Conditions")}</DialogTitle>
          <DialogContent className="dialog-text">
            <Conditions />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.termsDialogHandler} color="primary">
              {_t("Close")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth={"md"}
          open={isOpenTermsOfUse}
          onClose={this.termsOfUseDialogHandler}
          aria-labelledby="dialog-termsOfUse"
        >
          <DialogTitle id="dialog-termsOfUse">{_t("Terms of Use")}</DialogTitle>
          <DialogContent className="dialog-text">
            <Use />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.termsOfUseDialogHandler} color="primary">
              {_t("Close")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth={"md"}
          open={isOpenCookies}
          onClose={this.cookiesDialogHandler}
          aria-labelledby="dialog-cookies"
        >
          <DialogTitle id="dialog-cookies">{_t("Cookie Policy")}</DialogTitle>
          <DialogContent className="dialog-text">
            <Cookies />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.cookiesDialogHandler} color="primary">
              {_t("Close")}
            </Button>
          </DialogActions>
        </Dialog>
      </footer>
    );
  }
}

Footer.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(Footer);
