import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { Dialog, Toolbar, AppBar, IconButton, TextField, Button } from "@material-ui/core";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import "./index.scss";

class DialogRecommend extends PureComponent {
  recommendSchema = () => {
    const { t: _t } = this.props;
    return Yup.object().shape({
      name: Yup.string().required(_t("Required")),
      request: Yup.string().required(_t("Required")),
      data: Yup.string().required(_t("Required")),
    });
  };

  recommendSend = form => {
    console.warn("Данные DialogRecommend: ", form);
  };
  render() {
    const { t: _t } = this.props;
    return (
      <Dialog
        fullScreen
        open={this.props.isDialogRecommendOpen}
        onClose={this.props.closeDialogRecommend}
        aria-labelledby="dialog-recommend"
        className="dialog-recommend"
      >
        <AppBar className="dialog-recommend-appBar">
          <Toolbar className="dialog-recommend-toolBar">
            <div className="dialog-recommend-title">{_t("Request for recommendation")}</div>
            <IconButton
              color="inherit"
              style={{ fontSize: "14px" }}
              onClick={this.props.closeDialogRecommend}
              aria-label="close"
            >
              {_t("Close")}
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className="dialog-recommend-content">
          <Formik
            initialValues={{
              name: "",
              request: "",
              data: "",
            }}
            validationSchema={this.recommendSchema}
            validateOnBlur={true}
            validateOnChange={false}
            onSubmit={this.recommendSend}
            render={({ errors, values }) => (
              <Form className="dialog-recommend-form">
                <Field
                  name="name"
                  render={({ field }) => (
                    <TextField
                      type="text"
                      label="Your name"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      className="dialog-recommend-input"
                      error={!!errors.name}
                      helperText={errors.name}
                      {...field}
                    />
                  )}
                />
                <Field
                  name="request"
                  render={({ field }) => (
                    <TextField
                      margin="dense"
                      label="Add request"
                      multiline
                      variant="outlined"
                      rows="5"
                      rowsMax="40"
                      {...field}
                      className="dialog-recommend-input"
                      error={!!errors.request}
                      helperText={errors.request}
                    />
                  )}
                />
                <Field
                  name="data"
                  render={({ field }) => (
                    <TextField
                      type="text"
                      label="Add email or tel./mob. diagnostic center"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      className="dialog-recommend-input"
                      error={!!errors.data}
                      helperText={errors.data}
                      {...field}
                    />
                  )}
                />
                <Button type="submit" className="dialog-recommend-send">
                  {_t("Send")}
                </Button>
              </Form>
            )}
          />
        </div>
      </Dialog>
    );
  }
}

DialogRecommend.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(DialogRecommend);
