import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import CtLogInForm from "../../../../components/CtLogInForm";
import { Dialog, Toolbar, AppBar, IconButton, DialogTitle, Paper } from "@material-ui/core";
import "./index.scss";

class CtLogin extends PureComponent {
  render() {
    const { t: _t } = this.props;
    return (
      <Dialog
        fullScreen
        open={this.props.isCtLogInOpen}
        onClose={this.props.openCtLogInDialog}
        aria-labelledby="dialog-ctLogin"
        className="dialog-ctLogin"
      >
        <AppBar className="dialog-ctLogin-appBar">
          <Toolbar className="dialog-ctLogin-toolBar">
            <IconButton
              color="inherit"
              style={{ fontSize: "14px" }}
              onClick={this.props.openCtLogInDialog}
              aria-label="close"
            >
              {_t("Close")}
            </IconButton>
          </Toolbar>
        </AppBar>
        <Paper className="dialog-ctLogin-paper">
          <CtLogInForm />
        </Paper>
      </Dialog>
    );
  }
}

CtLogin.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(CtLogin);
