import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import propTypes from "prop-types";
import homepage_icon1 from "../../../assets/imgs/homepage-icon1.png";
import homepage_icon2 from "../../../assets/imgs/homepage-icon2.png";
import homepage_icon3 from "../../../assets/imgs/homepage-icon3.png";
import s3i1 from "../../../assets/imgs/s3i1.png";
import s3i2 from "../../../assets/imgs/s3i2.png";
import s3i3 from "../../../assets/imgs/s3i3.png";
import homes41 from "../../../assets/imgs/homes41.png";
import homes42 from "../../../assets/imgs/homes42.png";
import homes5 from "../../../assets/imgs/homes5.png";
import s421 from "../../../assets/imgs/s421.png";
import s422 from "../../../assets/imgs/s422.png";
import s423 from "../../../assets/imgs/s423.png";
import s424 from "../../../assets/imgs/s424.png";
import s425 from "../../../assets/imgs/s425.png";
import video from "../../../assets/videos/concept2.mp4";
import ConfirmEmail from "../ConfirmEmail";
// import ChangeEmail from "../DialogChangeEmail";
import ChangePassword from "../DialogChangePassword";
import "./index.scss";
import scrollToTop from "../../../modules/scrollToTop";

class HomePage extends PureComponent {
  state = {
    transform: 0,
    isDialogFindOpen: false,
    isConfirmEmailOpen: false,
  };

  componentDidMount() {
    scrollToTop();
    window.addEventListener("scroll", this.scrollHandler, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollHandler);
  }

  scrollHandler = () => {
    this.setState({
      transform: window.scrollY / 4,
    });
  };

  openConfirmEmailDialog = () => {
    this.setState({
      isConfirmEmailOpen: !this.state.isConfirmEmailOpen,
    });
  };

  render() {
    const { t: _t } = this.props;
    const { transform, isConfirmEmailOpen } = this.state;
    return (
      <div className="homePage">
        {/* <ChangeEmail /> */}
        <ChangePassword />
        <ConfirmEmail isConfirmEmailOpen={isConfirmEmailOpen} openConfirmEmailDialog={this.openConfirmEmailDialog} />
        <div className="section1" style={{ transform: `translate3d(0, ${transform}px, 0)` }}>
          <div className="container">
            <div className="section1__wrap">
              <h1>{_t("X-RAY CENTER")}</h1>
              <h2>
                <span>by</span> Colleagial
              </h2>
              <h6>{_t("Virtual Center for Digital Diagnostic Research")}</h6>
              <p>
                {_t(
                  "A platform for data storage, systematization and exchange of information in the field of dentistry and general medicine",
                )}
              </p>
              {/* <div className="vbtn" onClick={this.openConfirmEmailDialog}> */}
              <div className="vbtn">
                <Link to="/register">{_t("CREATE YOUR VIRTUAL DIAGNOSTIC CENTER")}</Link>
                {/* <p>{_t("CREATE YOUR VIRTUAL DIAGNOSTIC CENTER")}</p> */}
              </div>
            </div>
          </div>
        </div>

        <div className="simplePaper">
          <div className="section2">
            <div className="container">
              <div className="first">
                {[
                  {
                    id: 1,
                    pic: homepage_icon1,
                    title: _t("High-tech services"),
                    text: _t("An online resource provides quality business processes"),
                  },
                  {
                    id: 2,
                    pic: homepage_icon2,
                    title: _t("Modern communication"),
                    text: _t("Quality communication at all levels"),
                  },
                  {
                    id: 3,
                    pic: homepage_icon3,
                    title: _t("Data storage"),
                    text: _t("Storage and access to all data from any device"),
                  },
                ].map(el => (
                  <div className="first__item" key={el.id}>
                    <img src={el.pic} alt="" />
                    <h6>{el.title}</h6>
                    <p>{el.text}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="section3">
            <div className="container">
              <h2>{_t("If you are the owner or administrator of a digital research center")}</h2>
              <div className="first">
                {[
                  {
                    id: 1,
                    pic: s3i1,
                    text: _t(
                      "Create your organization through an Internet-based platform. Get your subdomain for your company profile. Customize the interface to your liking.",
                    ),
                  },
                  {
                    id: 2,
                    pic: s3i2,
                    text: _t(
                      "Register doctors who cooperate with you. Use invitations with automatic sending out emails. Simple registration for doctors.",
                    ),
                  },
                  {
                    id: 3,
                    pic: s3i3,
                    text: _t(
                      "Create medical patient charts in chats with doctors and upload all types of tests to them. Doctors receive online notifications on email and Messenger about the research submitted by the center. Use the Online Messenger to correspond with doctors.",
                    ),
                  },
                ].map(el => (
                  <div className="first__item" key={el.id}>
                    <div className="first__pic">
                      <img src={el.pic} alt="" />
                    </div>
                    <div className="first__text">{el.text}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="section41">
            <div className="container">
              <h2>{_t("User-friendly interface")}</h2>
              <p>{_t("The possibility of customizing the interface and make your organization’s account unique")}</p>
              <img src={homes41} alt="" className="section4__pic1" />
            </div>
          </div>
          <div className="section42">
            <div className="container">
              <h2>{_t("By registering on the web platform you can create your unique resource:")}</h2>
              <div className="items">
                {[
                  {
                    id: 1,
                    pic: s421,
                    text: _t("You get a separate page for the login of your customers with a separate address."),
                  },
                  {
                    id: 2,
                    pic: s422,
                    text: _t("You create a unique view of the login page."),
                  },
                  {
                    id: 3,
                    pic: s423,
                    text: _t("Choose the main colors of the interface."),
                  },
                  {
                    id: 4,
                    pic: s424,
                    text: _t("Choose the main background of the interface."),
                  },
                  {
                    id: 5,
                    pic: s425,
                    text: _t("Choose a picture for the background of the left site of the bar."),
                  },
                ].map(el => (
                  <div className="item" key={el.id}>
                    <div className="pic">
                      <img src={el.pic} alt="" />
                    </div>
                    <div className="text">{el.text}</div>
                  </div>
                ))}
              </div>
              <p>
                {_t(
                  "Together, all the possibilities to personalize the interface make your profile look unique. Thanks to all these features, your profile on this web platform can rightfully be called the web platform of your company.",
                )}
              </p>
            </div>
          </div>
          <div className="section43">
            <div className="container">
              <h2>{_t("You don't pay for the organization's account, you only pay for the created patient charts")}</h2>
              <p>{_t("You get the opportunity to create 20 patient charts for free.")}</p>
              <p>{_t("Each patient charts will cost 30 cents in the future.")}</p>
              <h3 className="imoprtant">
                {_t("1 CD media costs 30 cents that was previously used to transfer files")}
              </h3>
              <img src={homes42} alt="" className="section4__pic2" />
              <p>{_t("You don't spend more money, you improve work processes and communication with your doctors.")}</p>
            </div>
          </div>
          <div className="section5">
            <img src={homes5} alt="" />
            <div className="container">
              <div className="section5__wrap">
                <h2>
                  {_t(
                    "To create a profile for your organization, you will need to follow several steps for setting up your account",
                  )}
                </h2>
                {/* <div className="vbtn" onClick={this.openConfirmEmailDialog}> */}
                <div className="vbtn">
                  <Link to="/register">{_t("CREATE YOUR VIRTUAL DIAGNOSTIC CENTER")}</Link>
                  {/* <p>{_t("CREATE YOUR VIRTUAL DIAGNOSTIC CENTER")}</p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="section6">
            <div className="container">
              <h2>{_t("You can see how our interface works watching the video below")}</h2>
              <video src={video} autoPlay muted loop></video>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HomePage.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(HomePage);
