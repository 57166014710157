import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import "./index.scss";

class Conditions extends PureComponent {
  render() {
    const { t: _t } = this.props;
    return (
      <div className="footerModal">
        <h2>{_t("Privacy policy")}</h2>
        <p>
          {_t(
            "This privacy policy (‘Policy’) describes how Dentin corporation (‘Dentin corporation’, ‘we’, ‘us’ or ‘our’) collects, protects and uses the personally identifiable information (‘Personal Information’) you (‘User’, ‘you’ or ‘your’) may provide on the clear-in.com website and any of its products or services (collectively, ‘Website’ or ‘Services’). It also describes the choices available to you regarding our use of your Personal Information and how you can access and update this information. This Policy does not apply to the practices of companies that we do not own or control, or to individuals that we do not employ or manage.",
          )}
        </p>
        <h2>{_t("Automatic collection of information")}</h2>
        <p>
          {_t(
            "When you visit the Website our servers automatically record information that your browser sends. This data may include information such as your device's IP address, browser type and version, operating system type and version, language preferences or the webpage you were visiting before you came to our Website, pages of our Website that you visit, the time spent on those pages, information you search for on our Website, access times and dates, and other statistics.",
          )}
        </p>
        <h2>{_t("Collection of personal information")}</h2>
        <p>
          {_t(
            "You can visit the Website without telling us who you are or revealing any information by which someone could identify you as a specific, identifiable individual. If, however, you wish to use some of the Website's features, you will be asked to provide certain Personal Information (for example, your name and e-mail address). We receive and store any information you knowingly provide to us when you create an account, make a purchase, or fill any online forms on the Website. When required, this information may include your email address, name, phone number, address, or other Personal Information. You can choose not to provide us with your Personal Information, but then you may not be able to take advantage of some of the Website's features. Users who are uncertain about what information is mandatory are welcome to contact us.",
          )}
        </p>
        <h2>{_t("Managing personal information")}</h2>
        <p>
          {_t(
            "You are able to access, add to, update and delete certain Personal Information about you. The information you can view, update, and delete may change as the Website or Services change. When you update information, however, we may maintain a copy of the unrevised information in our records. Some information may remain in our private records after your deletion of such information from your account. We will retain and use your Personal Information for the period necessary to comply with our legal obligations, resolve disputes, and enforce our agreements unless a longer retention period is required or permitted by law. We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally. Once the retention period expires, Personal Information shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after the expiration of the retention period.",
          )}
        </p>
        <h2>{_t("Use and processing of collected information")}</h2>
        <p>
          {_t(
            "Any of the information we collect from you may be used to personalize your experience; improve our Website; improve customer service and respond to queries and emails of our customers; process transactions; send newsletters; send notification emails such as password reminders, updates, etc; run and operate our Website and Services. Information collected automatically is used only to identify potential cases of abuse and establish statistical information regarding Website usage. This statistical information is not otherwise aggregated in such a way that would identify any particular user of the system.",
          )}
        </p>
        <p>
          {_t(
            "We may process Personal Information related to you if one of the following applies: (i) You have given your consent for one or more specific purposes. Note that under some legislations we may be allowed to process information until you object to such processing (by opting out), without having to rely on consent or any other of the following legal bases below. This, however, does not apply, whenever the processing of Personal Information is subject to European data protection law; (ii) Provision of information is necessary for the performance of an agreement with you and/or for any pre-contractual obligations thereof; (iii) Processing is necessary for compliance with a legal obligation to which you are subject; (iv) Processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in us; (v) Processing is necessary for the purposes of the legitimate interests pursued by us or by a third party. In any case, we will be happy to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Information is a statutory or contractual requirement, or a requirement necessary to enter into a contract.",
          )}
        </p>
        <h2>{_t("Information transfer and storage")}</h2>
        <p>
          {_t(
            "Depending on your location, data transfers may involve transferring and storing your information in a country other than your own. You are entitled to learn about the legal basis of information transfers to a country outside the European Union or to any international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by us to safeguard your information. If any such transfer takes place, you can find out more by checking the relevant sections of this document or inquire with us using the information provided in the contact section.",
          )}
        </p>
        <h2>{_t("The rights of users")}</h2>
        <p>
          {_t(
            "You may exercise certain rights regarding your information processed by us. In particular, you have the right to do the following: (i) you have the right to withdraw consent where you have previously given your consent to the processing of your information; (ii) you have the right to object to the processing of your information if the processing is carried out on a legal basis other than consent; (iii) you have the right to learn if information is being processed by us, obtain disclosure regarding certain aspects of the processing and obtain a copy of the information undergoing processing; (iv) you have the right to verify the accuracy of your information and ask for it to be updated or corrected; (v) you have the right, under certain circumstances, to restrict the processing of your information, in which case, we will not process your information for any purpose other than storing it; (vi) you have the right, under certain circumstances, to obtain the erasure of your Personal Information from us; (vii) you have the right to receive your information in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that your information is processed by automated means and that the processing is based on your consent, on a contract which you are part of or on pre-contractual obligations thereof.",
          )}
        </p>
        <h2>{_t("The right to object to processing")}</h2>
        <p>
          {_t(
            "Where Personal Information is processed for the public interest, in the exercise of an official authority vested in us or for the purposes of the legitimate interests pursued by us, you may object to such processing by providing a ground related to your particular situation to justify the objection. You must know that, however, should your Personal Information be processed for direct marketing purposes, you can object to that processing at any time without providing any justification. To learn, whether we are processing Personal Information for direct marketing purposes, you may refer to the relevant sections of this document.",
          )}
        </p>
        <h2>{_t("How to exercise these rights")}</h2>
        <p>
          {_t(
            "Any requests to exercise User rights can be directed to the Owner through the contact details provided in this document. These requests can be exercised free of charge and will be addressed by the Owner as early as possible.",
          )}
        </p>
        <h2>{_t("Billing and payments")}</h2>
        <p>
          {_t(
            "In case of services requiring payment, we request credit card or other payment account information, which will be used solely for processing payments. Your purchase transaction data is stored only as long as is necessary to complete your purchase transaction. After that is complete, your purchase transaction information is deleted. All direct payment gateways adhere to the latest security standards as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover. Sensitive and private data exchange happens over a SSL secured communication channel and is encrypted and protected with digital signatures, and our Website is also in compliance with PCI vulnerability standards in order to create as secure of an environment as possible for Users. Scans for malware are performed on a regular basis for additional security and protection.",
          )}
        </p>
        <h2>{_t("Privacy of children")}</h2>
        <p>
          {_t(
            "We do not knowingly collect any Personal Information from children under the age of 13. If you are under the age of 13, please do not submit any Personal Information through our Website or Service. We encourage parents and legal guardians to monitor their children's Internet usage and to help enforce this Policy by instructing their children never to provide Personal Information through our Website or Service without their permission. If you have reason to believe that a child under the age of 13 has provided Personal Information to us through our Website or Service, please contact us. You must also be at least 16 years of age to consent to the processing of your Personal Information in your country (in some countries we may allow your parent or guardian to do so on your behalf).",
          )}
        </p>
        <h2>{_t("Cookies")}</h2>
        <p>
          {_t(
            "The Website uses ‘cookies’ to help personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you. We may use cookies to collect, store, and track information for statistical purposes to operate our Website and Services. You have the ability to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. To learn more about cookies and how to manage them, visit internetcookies.org",
          )}
        </p>
        <h2>{_t("Do Not Track signals")}</h2>
        <p>
          {_t(
            "Some browsers incorporate a Do Not Track feature that signals to websites you visit that you do not want to have your online activity tracked. Tracking is not the same as using or collecting information in connection with a website. For these purposes, tracking refers to collecting personally identifiable information from consumers who use or visit a website or online service as they move across different websites over time. How browsers communicate the Do Not Track signal is not yet uniform. As a result, this Website is not yet set up to interpret or respond to Do Not Track signals communicated by your browser. Even so, as described in more detail throughout this Policy, we limit our use and collection of your personal information.",
          )}
        </p>
        <h2>{_t("Links to other websites")}</h2>
        <p>
          {_t(
            "Our Website contains links to other websites that are not owned or controlled by us. Please be aware that we are not responsible for the privacy practices of such other websites or third-parties. We encourage you to be aware when you leave our Website and to read the privacy statements of each and every website that may collect Personal Information.",
          )}
        </p>
        <h2>{_t("Information security")}</h2>
        <p>
          {_t(
            "We secure information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure. We maintain reasonable administrative, technical, and physical safeguards in an effort to protect against unauthorized access, use, modification, and disclosure of Personal Information in its control and custody. However, no data transmission over the Internet or wireless network can be guaranteed. Therefore, while we strive to protect your Personal Information, you acknowledge that (i) there are security and privacy limitations of the Internet which are beyond our control; (ii) the security, integrity, and privacy of any and all information and data exchanged between you and our Website cannot be guaranteed; and (iii) any such information and data may be viewed or tampered with in transit by a third-party, despite best efforts.",
          )}
        </p>
        <h2>{_t("Data breach")}</h2>
        <p>
          {_t(
            "In the event we become aware that the security of the Website has been compromised or users Personal Information has been disclosed to unrelated third parties as a result of external activity, including, but not limited to, security attacks or fraud, we reserve the right to take reasonably appropriate measures, including, but not limited to, investigation and reporting, as well as notification to and cooperation with law enforcement authorities. In the event of a data breach, we will make reasonable efforts to notify affected individuals if we believe that there is a reasonable risk of harm to the user as a result of the breach or if notice is otherwise required by law. When we do, we will send you an email.",
          )}
        </p>
        <h2>{_t("Legal disclosure")}</h2>
        <p>
          {_t(
            "We will disclose any information we collect, use or receive if required or permitted by law, such as to comply with a subpoena, or similar legal process, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request. In the event we go through a business transition, such as a merger or acquisition by another company, or sale of all or a portion of its assets, your user account, and Personal Information will likely be among the assets transferred.",
          )}
        </p>
        <h2>{_t("Changes and amendments")}</h2>
        <p>
          {_t(
            "We may update this Privacy Policy from time to time in our discretion and will notify you of any material changes to the way in which we treat Personal Information. When changes are made, we will revise the updated date at the bottom of this page. We may also provide notice to you in other ways in our discretion, such as through contact information you have provided. Any updated version of this Privacy Policy will be effective immediately upon the posting of the revised Privacy Policy unless otherwise specified. Your continued use of the Website or Services after the effective date of the revised Privacy Policy (or such other act specified at that time) will constitute your consent to those changes. However, we will not, without your consent, use your Personal Data in a manner materially different than what was stated at the time your Personal Data was collected.",
          )}
        </p>
        <h2>{_t("Acceptance of this policy")}</h2>
        <p>
          {_t(
            "You acknowledge that you have read this Policy and agree to all its terms and conditions. By using the Website or its Services you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to use or access the Website and its Services.",
          )}
        </p>
        <h2>{_t("Acceptable use policy")}</h2>
        <p>
          {_t(
            "These acceptable use policy (‘Acceptable Use Policy’, ‘AUP’, ‘Policy’) is an agreement between Dentin corporation (‘Dentin corporation’, ‘us’, ‘we’ or ‘our’) and you (‘User’, ‘you’ or ‘your’). This Policy sets forth the general guidelines and acceptable and prohibited uses of the clear-in.com website and any of its products or services (collectively, ‘Website’ or ‘Services’).",
          )}
        </p>
        <h2>{_t("Prohibited activities and uses")}</h2>
        <p>
          {_t(
            "You may not use the Services to engage in activity that is illegal under applicable law, that is harmful to others, or that would subject us to liability, including, without limitation, in connection with any of the following, each of which is prohibited under this Policy:",
          )}
        </p>
        <ul>
          <li>{_t("Distributing malware or other malicious code.")}</li>
          <li>{_t("Disclosing sensitive personal information about others.")}</li>
          <li>
            {_t(
              "Collecting, or attempting to collect, personal information about third parties without their knowledge or consent.",
            )}
          </li>
          <li>{_t("Distributing pornography or adult related content.")}</li>
          <li>{_t("Promoting or facilitating prostitution or any escort services.")}</li>
          <li>{_t("Hosting, distributing or linking to child pornography or content that is harmful to minors.")}</li>
          <li>
            {_t("Promoting or facilitating gambling, violence, terrorist activities or selling weapons or ammunition.")}
          </li>
          <li>
            {_t(
              "Engaging in the unlawful distribution of controlled substances, drug contraband or prescription medications.",
            )}
          </li>
          <li>
            {_t(
              "Managing payment aggregators or facilitators such as processing payments on behalf of other businesses or charities.",
            )}
          </li>
          <li>{_t("Facilitating pyramid schemes or other models intended to seek payments from public actors.")}</li>
          <li>{_t("Threatening harm to persons or property or otherwise harassing behavior.")}</li>
          <li>
            {_t("Manual or automatic credit card or other available payment methods testing using bots or scripts.")}
          </li>
          <li>{_t("Purchasing any of the offered Services on someone else’s behalf.")}</li>
          <li>{_t("Misrepresenting or fraudulently representing products or services.")}</li>
          <li>{_t("Infringing the intellectual property or other proprietary rights of others.")}</li>
          <li>{_t("Facilitating, aiding, or encouraging any of the above activities through our Services.")}</li>
        </ul>
        <h2>{_t("System abuse")}</h2>
        <p>
          {_t(
            "Any User in violation of our Services security is subject to criminal and civil liability, as well as immediate account termination. Examples include, but are not limited to the following:.",
          )}
        </p>
        <ul>
          <li>{_t("Use or distribution of tools designed for compromising security of the Services.")}</li>
          <li>
            {_t("Intentionally or negligently transmitting files containing a computer virus or corrupted data.")}
          </li>
          <li>
            {_t(
              "Accessing another network without permission, including to probe or scan for vulnerabilities or breach security or authentication measures.",
            )}
          </li>
          <li>
            {_t(
              "Unauthorized scanning or monitoring of data on any network or system without proper authorization of the owner of the system or network.",
            )}
          </li>
        </ul>
        <h2>{_t("Service resources")}</h2>
        <p>
          {_t(
            "You may not consume excessive amounts of the Services or use the Services in any way which results in performance issues or which interrupts the services for other Users. Prohibited activities that contribute to excessive use, include without limitation:",
          )}
        </p>
        <ul>
          <li>
            {_t("Deliberate attempts to overload the Services and broadcast attacks (i.e. denial of service attacks).")}
          </li>
          <li>{_t("Engaging in any other activities that degrade the usability and performance of our Services.")}</li>
        </ul>
        <h2>{_t("No spam policy")}</h2>
        <p>
          {_t(
            "You may not use our Services to send spam or bulk unsolicited messages. We maintain a zero tolerance policy for use of our Services in any manner associated with the transmission, distribution or delivery of any bulk e-mail, including unsolicited bulk or unsolicited commercial e-mail, or the sending, assisting, or commissioning the transmission of commercial e-mail that does not comply with the U.S. CAN-SPAM Act of 2003 (‘SPAM’).",
          )}
        </p>
        <p>
          {_t(
            "Your products or services advertised via SPAM (i.e. Spamvertised) may not be used in conjunction with our Services. This provision includes, but is not limited to, SPAM sent via fax, phone, postal mail, email, instant messaging, or newsgroups.",
          )}
        </p>
        <p>
          {_t("Sending emails through our Services to purchased email lists (‘safe lists’) will be treated as SPAM.")}
        </p>
        <h2>{_t("Copyrighted content")}</h2>
        <p>
          {_t(
            "Copyrighted material must not be published via our Services without the explicit permission of the copyright owner or a person explicitly authorized to give such permission by the copyright owner. Upon receipt of a claim for copyright infringement, or a notice of such violation, we will immediately run full investigation and, upon confirmation, will promptly remove the infringing material from the Services. We may terminate the Service of Users with repeated copyright infringements. Further procedures may be carried out if necessary. We will assume no liability to any User of the Services for the removal of any such material.",
          )}
        </p>
        <p>
          {_t(
            "If you believe your copyright is being infringed by a person or persons using our Services, please send a report of the copyright infringement to the contact details listed at the end of this Policy. Your notice must include the following:",
          )}
        </p>
        <ul>
          <li>
            {_t(
              "Identification of the copyrighted work claimed to have been infringed, or if multiple copyrighted words at a single site are covered by a single notification, a representative list of such works at that site.",
            )}
          </li>
          <li>
            {_t(
              "Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material.",
            )}
          </li>
          <li>
            {_t(
              "Information reasonably sufficient to permit us to contact you, such as an address, telephone number, and, if available, an e-mail address.",
            )}
          </li>
          <li>
            {_t(
              "A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, the copyright owner's agent, or the law.",
            )}
          </li>
          <li>
            {_t(
              "A statement that the information in the notification is accurate, and under penalty of perjury that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.",
            )}
          </li>
          <li>
            {_t(
              "A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.",
            )}
          </li>
        </ul>
        <h2>{_t("Security")}</h2>
        <p>
          {_t(
            "You take full responsibility for maintaining reasonable security precautions for your account. You are responsible for protecting and updating any login account provided to you for our Services.",
          )}
        </p>
        <h2>{_t("Enforcement")}</h2>
        <p>
          {_t(
            "We reserve our right to be the sole arbiter in determining the seriousness of each infringement and to immediately take corrective actions, including but not limited to:",
          )}
        </p>
        <ul>
          <li>
            {_t(
              "Suspending or terminating your Service with or without notice upon any violation of this Policy. Any violations may also result in the immediate suspension or termination of your account.",
            )}
          </li>
          <li>
            {_t(
              "Disabling or removing any content which is prohibited by this Policy, including to prevent harm to others or to us or our Services, as determined by us in our sole discretion.",
            )}
          </li>
          <li>{_t("Reporting violations to law enforcement as determined by us in our sole discretion.")}</li>
          <li>
            {_t(
              "A failure to respond to an email from our abuse team within 2 days, or as otherwise specified in the communication to you, may result in the suspension or termination of your Services.",
            )}
          </li>
        </ul>
        <p>
          {_t(
            "Suspended and terminated User accounts due to violations will not be re-activated. A backup of User’s data may be requested, however, we may not be able to provide you with one and, as such, you are strongly encouraged to take your own backups.",
          )}
        </p>
        <p>
          {_t(
            "Nothing contained in this Policy shall be construed to limit our actions or remedies in any way with respect to any of the prohibited activities. We reserve the right to take any and all additional actions we may deem appropriate with respect to such activities, including without limitation taking action to recover the costs and expenses of identifying offenders and removing them from our Services, and levying cancellation charges to cover our costs. In addition, we reserve at all times all rights and remedies available to us with respect to such activities at law or in equity.",
          )}
        </p>
        <h2>{_t("Reporting violations")}</h2>
        <p>
          {_t(
            "If you have discovered and would like to report a violation of this Policy, please contact us immediately. We will investigate the situation and provide you with full assistance.",
          )}
        </p>
        <h2>{_t("Changes and amendments")}</h2>
        <p>
          {_t(
            "We reserve the right to modify this Policy or its terms relating to the Website or Services at any time, effective upon posting of an updated version of this Policy on the Website. When we do, we will revise the updated date at the bottom of this page. Continued use of the Website after any such changes shall constitute your consent to such changes.",
          )}
        </p>
        <h2>{_t("Acceptance of this policy")}</h2>
        <p>
          {_t(
            "You acknowledge that you have read this Policy and agree to all its terms and conditions. By using the Website or its Services you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to use or access the Website and its Services.",
          )}
        </p>
      </div>
    );
  }
}

Conditions.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(Conditions);
