import React, { Component } from "react";
import { withTranslation } from "react-i18next";
// import { connect } from "react-redux";
import propTypes from "prop-types";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import { TextField, Button } from "@material-ui/core";
import "./index.scss";

class ConfirmEmailForm extends Component {
  ConfirmEmailSchema = () => {
    const { t: _t } = this.props;
    return Yup.object().shape({
      email: Yup.string()
        .email()
        .required(_t("Mandatory input")),
    });
  };
  confirmEmail = ({ email }) => {
    console.log(email);
  };

  render() {
    const { t: _t } = this.props;
    return (
      <div className="confirmEmailForm">
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={this.ConfirmEmailSchema()}
          onSubmit={values => {
            this.confirmEmail(values);
          }}
          render={({ errors }) => (
            <Form>
              <div className="confirmEmailForm__bg">{_t("Confirmation Email")}</div>
              <div className="confirmEmailForm__wrap">
                <p>{_t("Enter your email address and we will send you a link to the registration form")}</p>
                <Field
                  name="email"
                  render={({ field }) => (
                    <TextField
                      type="string"
                      label={_t("Email Address")}
                      className="confirmEmailForm__input"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      error={!!errors.email}
                      helperText={errors.email}
                      {...field}
                    />
                  )}
                />
                <Button type="submit" variant="contained" color="primary" className="btn confirmEmailForm-btn">
                  {_t("Send an Email")}
                </Button>
              </div>
            </Form>
          )}
        />
      </div>
    );
  }
}

ConfirmEmailForm.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(ConfirmEmailForm);
